import React, { useEffect, useState } from "react";
import Card from "../Card";
import cn from "classnames";
import styles from "./Register.module.sass";
import Icon from "../../../components/Icon";
import TextInput from "../../../components/TextInput";
import Loader from "../../../components/Loader";
import firebase from "../../../services/firebaseServices";
import { createUser } from "../../../services/ApiServices";
import { Link } from "react-router-dom";
import Checkbox from "../../../components/Checkbox";
import TextInput1 from "../../../components/TextInput1";
import { BsFillEyeSlashFill, BsFillEyeFill } from "react-icons/bs";

const Register = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [passwordShown, setPasswordshown] = useState(false);
  const [passwordShown1, setPasswordshown1] = useState(false);
  const [mobNo, setMobNo] = useState(0);
  const [mobError, setMobError] = useState("");
  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState("");

  const firstNameFunction = (e) => {
    setError("");
    setFirstNameError("");
    if (e.target.value.length < 3)
      setFirstNameError("First name should be atleast 3 characters");
    else {
      setError("");
      setFirstNameError("");
      setFirstName(e.target.value);
    }
  };
  const lastNameFunction = (e) => {
    setError("");
    setLastNameError("");
    if (e.target.value.length < 3)
      setLastNameError("Last name should be atleast 3 characters");
    else {
      setError("");
      setLastNameError("");
      setLastName(e.target.value);
    }
  };
  const emailFunction = async (e) => {
    setError("");
    setEmailError("");
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String(e.target.value).toLowerCase())) {
      setEmail("");
      setEmailError("Please enter the valid email");
    } else {
      setEmailError("");
      setEmail(e.target.value);
    }
  };
  const mobileFunction = (e) => {
    setError("");
    setMobError("");
    const re = /^[0-9\b]+$/;
    if (!re.test(String(e.target.value).toLowerCase())) {
      setMobError("Please enter the valid mobile number");
    } else {
      setError("");
      setMobError("");
      setMobNo(e.target.value);
    }
  };
  const passwordFunction = (e) => {
    setError("");
    setPasswordError("");
    if (e.target.value.length < 8)
      setPasswordError("Password should be 8 characters");
    else {
      setError("");
      setPasswordError("");
      setPassword(e.target.value);
    }
  };
  const confirmPasswordFunction = (e) => {
    setError("");
    setConfirmPasswordError("");
    if (password !== e.target.value)
      setConfirmPasswordError("Password is incorrect");
    else {
      setError("");
      setConfirmPasswordError("");
      setConfirmPassword(e.target.value);
    }
  };

  const register = async (id) => {
    let result, body;

    body = {
      email: email,
      displayName: firstName,
      lastName: lastName,
      phoneNumber: mobNo,
      password: password,
      confirmPassword: confirmPassword,
      id: id,
    };

    // try {
    //   result = await createUser(body);
    // } catch (error) {
    //   setError(error.message);
    // }
    console.log(result);
    if (result.success) {
      console.log(result);
      // props.props.history.push('/login')
    } else {
      setError("Something went wrong please try again later.");
    }
  };
  const createAccount = async (id) => {
    if (!firstName) setError("First name is required!");
    else if (!lastName) setError("Last name is required!");
    else if (!email) setError("Email is required!");
    else if (!mobNo) setError("Mobile number is required!");
    else if (!password) setError("Password is required!");
    else if (!confirmPassword) setError("Confirm password is required!");
    else {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)

        .then((userInfo) => {
          console.log(userInfo);
          register(userInfo.user.uid);
        })
        .catch((err) => {
          if (err.code === "auth/email-already-in-use")
            setError("Account aleady exist please Login");

          console.log(err);
        });
    }
  };

  return (
    <>
      <div className={styles.registerDiv}>
        <h1>Create an new account</h1>
        <TextInput1
          className={styles.registerInput}
          label="First Name"
          name="name"
          type="text"
          placeholder="Enter first name"
          onChange={(e) => {
            firstNameFunction(e);
          }}
        />
        {firstNameError && <div className={styles.alert}>{firstNameError}</div>}
        <TextInput1
          className={styles.registerInput}
          label="Last Name"
          name="name"
          type="text"
          placeholder="Enter last name"
          onChange={(e) => {
            lastNameFunction(e);
          }}
        />
        {lastNameError && <div className={styles.alert}>{lastNameError}</div>}
        <TextInput1
          className={styles.registerInput}
          label="Email"
          name="email"
          type="email"
          placeholder="Enter email"
          onChange={(e) => {
            emailFunction(e);
          }}
        />
        {emailError && <div className={styles.alert}>{emailError}</div>}
        <TextInput1
          className={styles.registerInput}
          label="Mobile Number"
          name="number"
          //type="number"
          placeholder="Enter mobile number"
          onChange={(e) => {
            mobileFunction(e);
          }}
        />
        {mobError && <div className={styles.alert}>{mobError}</div>}
        <div className={styles.passwordShow}>
          <TextInput1
            className={styles.registerInput}
            label="Password"
            name="password"
            type={passwordShown ? "text" : "password"}
            placeholder="Enter password"
            onChange={(e) => {
              passwordFunction(e);
            }}
          />
          {passwordShown ? (
            <BsFillEyeFill
              onClick={() => {
                setPasswordshown(false);
              }}
            />
          ) : (
            <BsFillEyeSlashFill
              onClick={() => {
                setPasswordshown(true);
              }}
            />
          )}
          {passwordError && (
            <div className={styles.alerts}>{passwordError}</div>
          )}
        </div>

        <div className={styles.passwordShow1}>
          <TextInput1
            className={styles.registerInput}
            label="Confirm Password"
            name="password"
            type={passwordShown1 ? "text" : "password"}
            placeholder="Enter confirm password"
            onChange={(e) => {
              confirmPasswordFunction(e);
            }}
          />
          {passwordShown1 ? (
            <BsFillEyeFill
              onClick={() => {
                setPasswordshown1(false);
              }}
            />
          ) : (
            <BsFillEyeSlashFill
              onClick={() => {
                setPasswordshown1(true);
              }}
            />
          )}
          {confirmPasswordError && (
            <div className={styles.alerts}>{confirmPasswordError}</div>
          )}
        </div>
        {error && <div className={styles.alerts}>{error}</div>}
        <div className={styles.registerBtn}>
          <button
            className={cn("button", styles.registerbutton)}
            onClick={() => createAccount()}
          >
            Register
          </button>
          <button
            className={cn("button", styles.registerbutton)}
            onClick={() => {
              props.onClose();
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </>
  );
};

export default Register;
