
function currentNetwork() {
    return "OKTO"
}

export const config = {
    magicLinkApi: 'pk_live_FD8BF3DDCBDACFB5',
    // currentNetwork: "MATIC",
    OKTO: {
        name: "OKTO",
        symbol: "OKTO",
        rpcURL: "https://rpc-mainnet.maticvigil.com/",
        chainId: 137
    },
    ETH: {
        name: "Etherium",
        symbol: "ETH",
        chainId: 1
    },
    currentNetwork
}