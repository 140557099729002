import {
    GETUSER,
    GETUSERS,
    CLEARPOSTS
} from './UserTypes'


import { GetUser,GetUsers } from '../../services/ApiServices'


export const getUser = (uid) => {


    return async (dispatch) => {
        let result;
        // dispatch({ type: SETFIRSTLOADED })
        try {
            result = await GetUser(uid);
        }
        catch (e) {

            if (e.message.includes('token is expired or token is unauthorized, please login again')) {
            }

        }

        if (result&&result.success) {
            try{

            dispatch(GetUserResponse(result.user[0]))
            }
            catch(e){}
        }

    }

}

export const getUsers = () => {


    return async (dispatch) => {
        let result;
        // dispatch({ type: SETFIRSTLOADED })
        try {
            result = await GetUsers();
        }
        catch (e) {

            if (e.message.includes('token is expired or token is unauthorized, please login again')) {
            }

        }

        if (result&&result.success) {
            try{

            dispatch(GetUsersResponse(result.users))
            }
            catch(e){}
        }

    }

}

export const GetUsersResponse = (payload) => {

    return (dispatch) => {

        return dispatch({
            type: GETUSERS,
            payload: payload
        })
    }
}
export const GetUserResponse = (payload) => {

    return (dispatch) => {

        return dispatch({
            type: GETUSER,
            payload: payload
        })
    }
}

