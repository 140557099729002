import React, { useState,useEffect,useRef } from "react";
import Dropdown from "../components/filterDropdown"
import styles from "./Filter.module.sass";
import { Range, getTrackBackground } from "react-range";
import { IoFilter } from "react-icons/io5";
import { toBeVisible } from "@testing-library/jest-dom/dist/matchers";
import { FaTimes } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { filterAsset, Getfilter } from "../services/ApiServices";
import cn from "classnames"

const dateOptions = [
  { label: "Default", value: "" },
  { label: "Recently Added", value: "new" },
  { label: "Oldest", value: "old" },
  { label: "Ending Soon", value: "end" },
];
const priceOptions = [
  { label: "Default", value: "default" },
  { label: "Highest price", value: "htl" },
  { label: "Lowest price", value: "lth" },
];
const statusOptions = [
  { label: "All", value: "all" },
  { label: "Live", value: "live" },
  { label: "Ended", value: "ended" },
  { label: "Coming soon", value: "coming" },
];
const saleOptions = [
  { label: "WETH", value: "WETH" },
];
const Filter1 = ({filter,clear}) => {
 
  const [sales, setSales] = useState(saleOptions[0]);
  const [creator, setCreator] = useState(saleOptions[0]);
  const [date, setDate] = useState(dateOptions[0]);
  const [price, setPrice] = useState(priceOptions[0]);
  const [likes, setLikes] = useState(statusOptions[0]);
  const [status, setStatus] = useState(statusOptions[0]);
  const [values, setValues] = useState([0]);
  const [asset, setAsset] = useState([])
    const [visible,setVisible] = useState(true)
    const [isClear, setIsClear] = useState(false);
  const STEP = 0.1;
  const MIN = 0;
  const MAX = 100;
  const didMount = useRef(false);
  const {id} = useParams();
 
  const handleClick = (value) => {
    setPrice(value);
    setVisible(false);
  };

  useEffect(() => {
    let sortValue = ""
    let filterValue = ""
    let body = { 
      filter: {
        "collectionId":id,
      }, 
      // sort: {
      //   filterValue : sortValue
      // }, 
      search: {} ,
      limit :12
    };

    if (price.value === "default") {
      body.sort={
        "createdAt":-1
      }
    }
    else if (price.value === "htl") {
      body.sort={
        "tokenPrice":-1,
        "createdAt":-1
      }
    }
    else if (price.value === "lth") {
      body.sort={
        "tokenPrice":1,
        "createdAt":-1
      }
    }

    filter(id,body)
  }, [price.value])

  return (
    <div className={styles.filter}>
      <div className={styles.sorting}>
      <div className={styles.head}>Filters 
      {/* {!visible ?<IoFilter className={styles.filterIcon} onClick={()=>setVisible(!visible)} />:
      <FaTimes className={styles.filterIcon} onClick={()=>setVisible(!visible)} />} */}
      <IoFilter className={styles.filterIcon}  />
      {!visible ?<IoFilter className={styles.mobfilterIcon} onClick={()=>setVisible(!visible)} />:
      <FaTimes className={styles.mobfilterIcon} onClick={()=>setVisible(!visible)} />}
      </div>
      {visible && <>
        <div className={styles.cell}>
          <div className={styles.label}>Price</div>
          <Dropdown
            className={styles.dropdown}
            value={price}
            setValue={setPrice}
            options={priceOptions}
            // onClick={() => filterassets(priceOptions.value)}
          />
        </div>
        {/* <div className={styles.cell}>
          <div className={styles.label}>Status</div>
          <Dropdown
            className={styles.dropdown}
            value={status}
            setValue={setStatus}
            options={statusOptions}
          />
        </div> */}
        {/* <div className={styles.cell}>
          <div className={styles.label}>On Sale In</div>
          <Dropdown
            className={styles.dropdown}
            value={creator}
            setValue={setCreator}
            options={saleOptions}
          />
        </div> */}
        <div className={styles.cell}>
          {/* <div className={styles.label}>Price Range</div> */}
          {/* <Range
            values={values}
            step={STEP}
            min={MIN}
            max={MAX}
            onChange={(values) => setValues(values)}
            renderTrack={({ props, children }) => (
              <div
                onMouseDown={props.onMouseDown}
                onTouchStart={props.onTouchStart}
                style={{
                  ...props.style,
                  height: "27px",
                  display: "flex",
                  width: "100%",
                  marginTop: "12px"
                }}
              >
                <div
                  ref={props.ref}
                  style={{
                    position: "absolute",
                    top: "20px",
                    height: "8px",
                    width: "100%",
                    borderRadius: "4px",
                    backgroundColor: "#4f42c9",
                  }}
                >
                  {children}
                </div>
              </div>
            )}
            renderThumb={({ props, isDragged }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  height: "24px",
                  width: "24px",
                  borderRadius: "50%",
                  backgroundColor: "#4f42c9",
                  border: "4px solid #FCFCFD",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  className={styles.title12345}
                  style={{
                    position: "absolute",
                    top: "-33px",
                    color: "#fff",
                    fontWeight: "600",
                    fontSize: "14px",
                    lineHeight: "18px",
                    padding: "4px 8px",
                    borderRadius: "8px",
                    backgroundColor: "#141416",
                  }}
                >
                  {values[0].toFixed(1)}
                </div>
              </div>
            )}
          /> */}
          {/* <div className={styles.scale}>
                <div className={styles.number}>0.01 {Bid}</div>
                <div className={styles.number}>10 {Bid}</div>
              </div> */}
        </div>
        </>}
        {isClear && (
              <button
                className={cn("button-stroke button-small", styles.clearButton)}
                onClick={() => {
                  setIsClear(false);
                  clear();
                }}
              >
                Clear Filters
              </button>
            )}
      </div>
    </div>
  );
};
export default Filter1;
