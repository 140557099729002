import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./Item.module.sass";
import Modal from "../../components/Modal";
import Purchase from "../../components/Purchase";
import { useSnackbar } from "notistack";
import OutsideClickHandler from "react-outside-click-handler";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
  Link,
} from "react-router-dom";
import { AddCommnets, GetAssetById, GetUSD } from "../../services/ApiServices";
import Loader from "../../components/Loader";
import Dropdown from "../../components/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "antd";
import Icon from "../../components/Icon";
import Edit from '../EditAsset'
import { PropertySafetyFilled } from "@ant-design/icons";
const actionOptions = ["Sell NFT", "Edit NFT", "Delete NFT","View on Store"];

const Item = ({history}) => {
  const state = useSelector((state) => state);
  const { enqueueSnackbar } = useSnackbar();
  const [visible, setVisible] = useState(false);
  const [action, setAction] = useState(actionOptions[0]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const [asset, setAsset] = useState({});
  const [visibleModalBid, setVisibleModalBid] = useState(false);
  const [nav, setNav] = useState("");
  const [item, setItem] = useState({});
  const [value, setValue] = useState(0);
  const [comment, setComment] = useState("");
  const [uid, setUid] = useState("");
  const [limit, setLimit] = useState(2);
  const [user, setUser] = useState({});
  const [isEdit,setIsEdit] =useState(false)
  const items = [
    {
      title: "My profile",
      icon: "user",
      url: "/profile/" + user.id,
    },
    // {
    //   title: "My items",
    //   icon: "image",
    //   url: "/Explore",
    // },
    {
      title: "Dark theme",
      icon: "bulb",
    },
    {
      title: "Disconnect",
      icon: "exit",
    },
  ];

  useEffect(() => {
    setUser(state.User.user);
  }, [state.User.user]);

  const getAsset = async () => {
    setIsLoading(true);
    let result;
    try {
      result = await GetAssetById(id);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }

    try {
      if (result.success) {
        setIsLoading(false);
        setAsset(result.posts[0]);
      }
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };
  const handleSubmit = async (e, id) => {
    e.preventDefault();
    if (comment) {
      if (user.id) {
        let result;
        let body = {
          userId: user.id,
          postId: id,
          comment: comment,
        };
        try {
          result = await AddCommnets(body);
        } catch (e) {
          console.log(e);
        }

        try {
          if (result.success) {
            getAsset();
            setComment("");
            console.log(result);
          }
        } catch (e) {
          console.log(e);
        }
      } else {
        enqueueSnackbar("Please Login!", { variant: "error" });
      }
    }
  };
  useEffect(()=>{
    if(action== 'Edit NFT'){
      console.log(history);
      history.push('/Seller-item/'+asset.id+'/edit')
    } 
  },[action])

  useEffect(() => {
    let id = localStorage.getItem("uid");
    setUid(id);
  }, [id]);

  useEffect(() => {
    setIsLoading(true);
    getAsset();
  }, []);
  const getUSD = async () => {
    let result;
    try {
      result = await GetUSD();
    } catch (e) {
      console.log(e);
    }

    try {
      if (result) {
        setValue(result.USD);
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    getUSD();
  }, []);

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      {isLoading && <Loader className={styles.loader} />}
      {asset.id && (
        <div className={cn("section", styles.section)}>
          <div className={cn("container", styles.container)}>
            <div className={styles.heading}>
              <Link
                to={"/Seller-Nft"}
                className={cn("button-stroke", styles.button)}
              >
                <Icon name="arrow-prev" />
              </Link>
              <div className={styles.title}>NFT Details</div>

              <div className={styles.dropdown}>
            <Dropdown
              className={styles.dropdown}
              value={action}
              setValue={setAction}
              options={actionOptions}
            />
          </div>
            </div>

            <Row wrap="false" gutter={24}>
              <Col span={12} className={styles.card}>
                <Row>
                  <Col span={12}>
                    <div className={styles.field}>Name</div>
                    <div className={styles.value}>{asset.title}</div>
                    <div className={styles.field}>Edition</div>
                    <div className={styles.value}>{asset.totalSupply}</div>
                    <div className={styles.field}>Available Stock</div>
                    <div className={styles.value}>{asset.stockAvailable}</div>
                    <div className={styles.field}>Wallet</div>
                    <div className={styles.value}>{asset.blockchainName}</div>
                    <div className={styles.field}>Asset Type</div>
                    <div className={styles.value}>{asset.assetType}</div>
                    <div className={styles.field}>Contract Address</div>
                    <div className={styles.value}>{asset.contractAddress}</div>
                    <div className={styles.field}>Description</div>
                    <div className={styles.value}>{asset.description}</div>
                  </Col>
                  <Col span={12}>
                  <div className={styles.field}>Price</div>
                    <div className={styles.value}>{asset.tokenPrice}</div>
                    <div className={styles.field}>Status</div>
                    <div className={styles.value}>On sale</div>
                    <div className={styles.field}>Category</div>
                    <Row gutter={24}>
                    {asset.tags.map((data) => {
                      return <Col className={styles.tag}>{data}</Col>;
                    })}
                    </Row>
                  </Col> 
                </Row>
              </Col>
              <Col span={11} className={styles.card}>
                <h2>Preview</h2>
                <div className={styles.preview}>
                  {asset.attachments[0].fileType == "image" ? (
                    <>
                      <img
                        className={styles.bg}
                        srcSet={`${asset.attachments[0].url} 2x`}
                        src={asset.attachments[0].url}
                        alt="Card"
                      />
                   
                    </>
                  ) : (
                    <video
                      autoPlay
                      muted
                      loop
                      src={asset.attachments[0].url}
                      type="video/mp4"
                    />
                  )}
                  <div className={styles.field}>{asset.title}</div>
                <div className={styles.value}>Edition {asset.totalSupply} of {asset.stockAvailable}</div>
                </div>

              </Col>
            </Row>
          </div>

          <Modal
            visible={visibleModalBid}
            onClose={() => setVisibleModalBid(false)}
          >
            <Purchase item={item} onClose={() => setVisibleModalBid(false)} />
          </Modal>
        </div>
      )}
    </OutsideClickHandler>
  
  );
};

export default Item;
