import React, { useState, useEffect } from "react";
import cn from "classnames";
import { Link, useParams } from "react-router-dom";
import styles from "./Profile.module.sass";
import Icon from "../../components/Icon";
import Discover2 from "../Home/Discover2";
import User from "./User";
import Items from "./Items";
import Followers from "./Followers";
import { isStepDivisible } from "react-range/lib/utils";
import {
  GetFollowers,
  GetFollowings,
  GetUserAssets,
  GetBuyerUser,
  GetUser,
} from "../../services/ApiServices";
import { useDispatch, useSelector } from "react-redux";
const navLinks = [
  "Listed for sale",
  "Collected",
  // "Created",
  // "Likes",
  // "Following",
  // "Followers",
];

const Profile = () => {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const [activeIndex, setActiveIndex] = useState(0);
  const [visible, setVisible] = useState(false);
  const [assets, setAssets] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [following, setFollowing] = useState([]);
  const [followers, setFollowers] = useState([]);
  const [user, setUser] = useState({});
  const [currentUser, setCurrentUser] = useState({});
  const [userLoading, setUserLoading] = useState(false);
  const [isFollow,setIsFollow] = useState(false);
  const [email, setEmail] = useState("")
  const [uId, setUid] = useState("")
  const [username, setUsername] = useState("")
  const [nftAddress, setNftAddress] = useState("")
  const { uid } = useParams();
  useEffect(() => {
    let email=localStorage.getItem('email')
    setEmail(email)
    let name=localStorage.getItem('name')
    setUsername(name)
    let uid=localStorage.getItem('uid')
    setUid(uid)
    let address=localStorage.getItem('nftAddress')
    setNftAddress(address)
  }, []);
  // const {uid} = uId

  // useEffect(() => {
  //   setCurrentUser(state.User.user);
  //   getAssets();
  //   getUser();
  // }, [state.User.user]);

  const getAssets = async () => {
    setIsLoading(true);
    let result;

    try {
      result = await GetUserAssets(uid);
    } catch (e) {
      console.log(e);
    }

    try {
      if (result.success) {
        setIsLoading(false);
        setAssets(result.posts);
        
      }
    } catch (e) {
      setIsLoading(false);

      console.log(e);
    }
  };
  const getUser = async () => {
    setUserLoading(true);
    let result;

    try {
      result = await GetUser(uid);
    } catch (e) {
      console.log(e);
    }

    // try {
    //   if (result.success) {
    //     setUserLoading(false);
    //     setUser(result.user[0]);
    //     if(result.user[0].followers.length>0)
    //     getFollowers(result.user[0].followers);
    //     else
    //     setFollowers([])
    //     if(result.user[0].following.length>0)
    //     getFollowing(result.user[0].following);
    //     else 
    //     setFollowing([])

    //     var follow=result.user[0].followers.some((element) => {
    //       console.log(element,currentUser.id);
    //       return element ===state.User.user.id;
    //     })
    //     setIsFollow(follow);
    //   }
    // } catch (e) {
    //   setUserLoading(false);

    //   console.log(e);
    // }
  };
  const getFollowers = async (followers) => {
    let result;

    var unique = [...new Set(followers)];
    unique = unique.slice(0, 100);
    try {
      result = await GetFollowers(unique);
    } catch (e) {
      console.log(e);
    }

    try {
      if (result.success) {
        setIsLoading(false);
        setFollowers(result.users);
      }
    } catch (e) {
      setIsLoading(false);

      console.log(e);
    }
  };
  const getFollowing = async (following) => {
    let result;
    let unique = Array.from(new Set(following));
    unique = unique.slice(0, 100);
    try {
      result = await GetFollowings(unique);
    } catch (e) {
      console.log(e);
    }

    try {
      if (result.success) {
        setFollowing(result.users);
      }
    } catch (e) {
      setIsLoading(false);

      console.log(e);
    }
  };
  useEffect(() => {
    
  }, []);

  return (
    <div className={styles.profile}>
      {/* <div
        className={cn(styles.head, { [styles.active]: visible })}
        style={{
          backgroundImage: "url(/images/content/bg-profile.jpg)",
        }}
      >
        <div className={cn("container", styles.container)}>
          <div className={styles.btns}>
            <button
              className={cn("button-stroke button-small", styles.button)}
              onClick={() => setVisible(true)}
            >
              <span>Edit cover photo</span>
              <Icon name="edit" size="16" />
            </button>
            <Link
              className={cn("button-stroke button-small", styles.button)}
              to="/profile-edit"
            >
              <span>Edit profile</span>
              <Icon name="image" size="16" />
            </Link>
          </div>
          <div className={styles.file}>
            <input type="file" />
            <div className={styles.wrap}>
              <Icon name="upload-file" size="48" />
              <div className={styles.info}>Drag and drop your photo here</div>
              <div className={styles.text}>or click to browse</div>
            </div>
            <button
              className={cn("button-small", styles.button)}
              onClick={() => setVisible(false)}
            >
              Save photo
            </button>
          </div>
        </div>
        
      </div>
      */}
      <div className={styles.body}>
        <div className={cn("container", styles.container)}>
           <User className={styles.user} item={user} getUser={getUser} isFollow={isFollow} />
           
          {/* <div className={styles.wrapper}> */}
          {/* <Discover2/> */}
          <Items/>
            {/* <div className={styles.group}>
              <div className={styles.item}>
                {activeIndex === 0 && (
                  <Items class={styles.items} items={assets}isLoading={isLoading} />
                )}
                {activeIndex === 1 && (
                  <Items class={styles.items} items={assets} />
                )}
                {activeIndex === 2 && (
                  <Items class={styles.items} items={assets} />
                )}
                {activeIndex === 3 && (
                  <Items class={styles.items} items={assets} />
                )}
                {activeIndex === 4 && (
                  <Followers className={styles.followers} items={following} />
                )}
                {activeIndex === 5 && (
                  <Followers className={styles.followers} items={followers} />
                )}
              </div>
            </div> */}
           
          </div>
        </div>
      {/* </div> */}

    </div>
  );
};

export default Profile;
