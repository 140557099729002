import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Login.module.sass";
import Loader from "../../../components/Loader";
import firebase from "../../../services/firebaseServices";
import { PostAsset } from "../../../services/ApiServices";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../../redux";
import { Link } from "react-router-dom";
import TextInput1 from "../../../components/TextInput1";

function Login(props) {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [error, setError] = useState("");

  const [selected, setSelected] = useState([]);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [userName, setUserName] = useState(localStorage.getItem("name"))
  const [emailId, setEmailId] = useState(localStorage.getItem("email"))

  const emailFunction = async (e) => {
    setError("");
    setEmailError("");
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String(e.target.value).toLowerCase())) {
      setEmail("");
      setEmailError("Please enter the valid Email");
    } else {
      setEmailError("");
      setEmail(e.target.value);
    }
  };
  const passwordFunction = (e) => {
    setError("");
    if (e.target.value.length < 8)
      setPasswordError("Password Should be 8 characters");
    else {
      setError("");
      setPasswordError("");
      setPassword(e.target.value);
    }
  };

  const login = () => {
    if (!email) setError("Email is required!");
    else if (!password) setError("Password is required!");
    else {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then((value) => {
          props.onClose()
          // props.props.history.push('/')
          console.log(value)
          setUserName(value.user.displayName)
          setEmailId(value.user.email)
          localStorage.setItem('name',value.user.displayName);
          localStorage.setItem('email',value.user.email);
          // dispatch(getUser(value.user.uid));
        })
        .catch((err) => {
          if (err.code === "auth/user-not-found")
            setError("User Not Found please register");
          else if (err.code === "auth/wrong-password")
            setError("Invalid Password");
          console.log(err);
        });
    }
  };
  useEffect(() => {
    let name=localStorage.getItem('name')
    setUserName(name)
    let email=localStorage.getItem('email')
    setEmailId(email)
  }, [userName,emailId]);
  return (
    <>
      <div className={styles.loginDiv}>
        <h3 className={styles.header1}>Login With Email</h3>
        <TextInput1
          className={styles.registerInput}
          label="Email"
          name="email"
          type="email"
          placeholder="Enter email"
          onChange={(e) => {
            emailFunction(e);
          }}
        />
        {emailError && <div className={styles.alert}>{emailError}</div>}
        <TextInput1
          className={styles.registerInput}
          label="Password"
          name="password"
          type="text"
          placeholder="Enter password"
          onChange={(e) => {
            passwordFunction(e);
          }}
        />
        {passwordError && <div className={styles.alert}>{passwordError}</div>}
        {error && <div className={styles.alert}>{error}</div>}
        {/* <div>If you already don't have an account? Please register <span onClick={() => {props.visible()}}>here</span></div> */}
        <div className={styles.buttons}>
          <button
            className={cn("button", styles.cancelbutton)}
            onClick={() => login()}
          >
            Login
          </button>
          <button
            className={cn("button", styles.cancelbutton)}
            onClick={() => {
              props.onClose();
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </>
  );
}

export default Login;
