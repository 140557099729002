
import {
    GETPOSTS,
    GETUSERPOSTS,
    CLEARGETPOSTS,
    GETSEARCHPOSTS,
    GETLASTKEY,
    GETPOSTSLOADNIG,
    CLEARGETPOSTSLOADNIG,
    POST_CANCELLED,
    SETFIRSTLOADED,
    POST_ADDED,
    CREATE_POST,
    CREATE_POST_RESPONSE,
    CREATE_POST_RESPONSE_CLEAR,
    CREATE_POST_ERROR,
    CREATE_POST_ERROR_CLEAR,
    EDIT_POST,
    EDIT_POST_ERROR,
    EDIT_POST_ERROR_CLEAR,
    EDIT_POST_RESPONSE,
    EDIT_POST_RESPONSE_CLEAR,
    POST_CANCELLED_CLEAR,
    CLEARPOSTS
} from './PostsTypes'

const initialState = {
    posts: [],
    searchposts: [],
    userPost:[],
    lastKey:{},
    postLoading: true,
    error: '',
    isFirstLoaded: false,
    isSearchLoaded: false,
}



const PostsReducer = (state = initialState, action) => {

    switch (action.type) {

        case CREATE_POST:
            return { ...state, isAdding: true }
            
        case EDIT_POST:
            return { ...state, isUpdating: true }

        case EDIT_POST_RESPONSE:
            let postsList = state.posts.map((data, index) => {
                if (data._id === action.payload._id) {
                    return action.payload
                }
                else {
                    return data
                }
            })
            return { ...state, posts: postsList, isUpdated: true, isUpdating: false }

        case EDIT_POST_RESPONSE_CLEAR:
            return { ...state, isUpdated: false }
        case EDIT_POST_ERROR:
            return { ...state,updateError:action.payload, isUpdating: false }
        case EDIT_POST_ERROR_CLEAR:
                 return { ...state, updateError: '' }
       

        case CREATE_POST_RESPONSE:
            let listPost = state.posts
            return { ...state, isAdding: false, updateFilterPost: true, isAdded: true, posts: [action.payload, ...listPost] }
        case CREATE_POST_RESPONSE_CLEAR:
            return { ...state, isAdded: false }
        case CREATE_POST_ERROR:
            return { ...state, addingError: action.payload, isAdding: false }
        case CREATE_POST_ERROR_CLEAR:
            return { ...state, addingError: '' }
        case SETFIRSTLOADED:

            return { ...state, isFirstLoaded: true,isSearchLoaded: true }
        case GETPOSTS:
            return { ...state, posts: action.payload, postLoading: false, isFirstLoaded: true }
        case GETUSERPOSTS:
            return { ...state, userPost: action.payload }
        case GETLASTKEY:
            return { ...state, lastKey: action.payload}
        case GETSEARCHPOSTS:
            return { ...state, searchposts: action.payload, isSearchLoaded: true }
        case POST_CANCELLED:
            let post_list = state.posts.filter(data => { return data._id != action.payload })
            return { ...state, posts: post_list ,isPostCancelled:true }

            case POST_CANCELLED_CLEAR:
                return { ...state, isPostCancelled:false,isUpdating:false}

            case CLEARPOSTS:
                return {
                    posts: [],
                    postLoading: true,
                    error: '',
                    isFirstLoaded: false,
                    isAdding: false,
                    isAdded: false,
                    addingError: '',
                    isUpdated: false,
                    isUpdating: false,
                    updateError:'',
                    isPostCancelled:false
                }

                    

        default: return state;
    }



}

export default PostsReducer