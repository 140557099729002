import React, { useState } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import styles from "./Footer.module.sass";
import Group from "./Group";
import Image from "../Image";
import Form from "../Form";
import Theme from "../Theme";
import { Col, Row } from "antd";
import Logo from "../../images/footerLogo.png";
import Facebook from "../../images/Exclusion 1.png";
import Twitter from "../../images/Path 96.png";
import Insta from "../../images/Path 98.png";
import Linkin from "../../images/Exclusion 2.png";
const items = [
  {
    title: "Marketplace",
    menu: [
      {
        title: "Explore Collections",
        url: "/collection",
      },
      {
        title: "FAQ",
        url: "/faq",
      },
      {
        title: "Support",
        url: "/support",
      },
      {
        title: "Terms & Conditions",
        url: "https://wowt-termsandconditions-policy.s3.amazonaws.com/tandc/wowTtermsandcondition.html",
      },
      {
        title: "Privacy Policy",
        url: "https://wowt-termsandconditions-policy.s3.amazonaws.com/tandc/wowTprivatepolicy.html",
      },
    ],
  },
];

const Footers = (history) => {
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    alert();
  };

  return (
    <footer className={styles.footer}>
      <div className={cn("container", styles.container)}>
        <div className={styles.footerFlex}>
          <div className={styles.socialMedia}>
            <Link className={styles.logo} to="/">
              <img src={Logo} alt="" />
            </Link>
            <div className={styles.images}>
            <a href="https://twitter.com/TalkiesWow" target="_blank"><img src={Twitter} alt="" className={styles.socialImg} /></a>
              <a href="https://www.instagram.com/wowtalkies" target="_blank"><img src={Insta} alt="" className={styles.socialImg} /></a>
              {/* <a href="https://www.facebook.com/tamilwowTalkies" target="_blank"><img src={Facebook} alt="" className={styles.socialImg} /></a> */}
              <a href="https://t.me/wowTalkies" target="_blank"><img src={Facebook} alt="" className={styles.socialImg} /></a>
              <a href="https://www.linkedin.com/company/wowtalkies/" target="_blank"><img src={Linkin} alt="" className={styles.socialImg} /></a>
              {/* <img src={Facebook} alt="" /> */}
              {/* <img src={Linkin} alt="" />             */}
              </div>
          </div>
          <div className={styles.row}>
            <Row>
              {items.map((x, index) => (
                // <Col md={6} lg={6} xs={12} sm={12}>
                <div className={styles.col}>
                  <Group className={styles.group} item={x} key={index} />
                </div>
                // </Col>
              ))}
            </Row>
          </div>
        </div>
        <div className={styles.foot}>©  2022 wowTalkies. All rights reserved</div>
      </div>
    </footer>
    
  );
};

export default Footers;
