import React from "react";
import HotBid from "../../components/HotBid";
import { getUsers } from "../../redux";
import { useDispatch, useSelector } from 'react-redux'
import Description2 from "./Description2";
import Selection2 from "./Selection2";
import Popular2 from "./Popular2";





const Home2 = () => {
  const state = useSelector(state => state)
  const dispatch = useDispatch()
//   if(state.Posts.isFirstLoaded===false){
//     dispatch(getPosts())
// }
if(state.User.usersLoading===true)
{
  dispatch(getUsers())
}
  return (
    <>
    <Description2/>
    <Selection2/>
    <Popular2/>

  

     
    </>
  );
};

export default Home2;
