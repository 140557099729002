import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames';
import styles from './collectionDetail.module.sass';
import Modal from '../../components/Modal';
import Purchase from '../../components/Purchase';
import { useSnackbar } from 'notistack';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
  useHistory,
} from 'react-router-dom';
import {
  AddCommnets,
  GetAssetById,
  getInventoryDetails,
  GetUSD,
  GetNftId,
  GetTrendingId,
  GetNftByAssetId,
  GetCollection,
  GetMaticPrice,
} from '../../services/ApiServices';
import Loader from '../../components/Loader';
import TextInput from '../../components/TextInput';
import { useDispatch, useSelector } from 'react-redux';
import HotBid from '../../components/HotBid';
import { Col, Collapse, Row, Table } from 'antd';
import { Link } from 'react-router-dom';
import { Line } from 'react-chartjs-2';
import '../Item/Users/user.css';
import moment from 'moment';
import eth from '../../components/img/Group 239.png';
import { Posts } from '../../json/Assets';
import { FaEthereum } from 'react-icons/fa';
import Bid from '../../mocks/bids';
import Polygon from '../../images/polygon.png';
// import { nfts } from "../../data/nfts";
import TextInput1 from '../../components/TextInput1';
import { HiPlus, HiMinus } from 'react-icons/hi';
import Eth from '../../images/eth 2.png';
import {
  getDatabase,
  ref as referance,
  onValue,
  set,
  push,
} from 'firebase/database';
import { getAuth } from 'firebase/auth';
import { useWeb3React } from '@web3-react/core';
import { storeLocation } from '../../redux/Location/LocationActions';
import LocationReducer from '../../redux/Location/LocationReducer';
import CONFIG from '../../utils/contractConfig';

const { Panel } = Collapse;

const navLinks = ['Info', 'Owners', 'History', 'Chain Info'];

const categories = [
  {
    category: 'black',
    content: 'art',
  },
  {
    category: 'purple',
    content: 'unlockable',
  },
];

const CollectionDetail = (props) => {
  const { account, active, activate, error } = useWeb3React();
  // const darkMode = useDarkMode(false);
  const ref = useRef(null);
  const state = useSelector((state) => state);
  const { enqueueSnackbar } = useSnackbar();
  const [activeIndex, setActiveIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const { json } = useParams();
  const [assets, setAssets] = useState({});
  const [asset, setAsset] = useState({});
  const [visibleModalBid, setVisibleModalBid] = useState(false);
  const [openCheckout, setOpenCheckout] = useState(false);
  const [nav, setNav] = useState('');
  const [item, setItem] = useState({});
  const [value, setValue] = useState([]);
  const [comment, setComment] = useState('');
  const [uid, setUid] = useState('');
  const [limit, setLimit] = useState(2);
  const [user, setUser] = useState({});
  const [inventoryItem, setInventoryItem] = useState({});
  const [inventoryItems, setInventoryItems] = useState({});
  const [attributes, setAttributes] = useState(true);
  const [detail, setDetail] = useState(true);
  const [kycVerification, setKycVerification] = useState('');
  const dispatch = useDispatch();
  let history = useHistory();
  // console.log(history?.location?.pathname)
  // const [assets, setAssets] = useState([]);
  // console.log(dispatch(storeLocation()));
  // if (state.LocationReducer.visibleModalBid === false) {
  //   dispatch(LocationReducer());
  // }
  const getUSD = async () => {
    let result;
    try {
      result = await GetMaticPrice();
    } catch (e) {
      console.log(e);
    }

    try {
      if (result) {
        setValue(result.USD);
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    getUSD();
  }, []);

  const getCollection = async () => {
    setIsLoading(true);
    let result;
    try {
      result = await GetCollection(id);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
    try {
      if (result.success) {
        setIsLoading(false);
        setAssets(result.collection);
      }
    } catch (e) {
      setIsLoading(false);

      console.log(e);
    }
  };
  const getNftByAssetId = async () => {
    setIsLoading(true);
    let result;

    try {
      result = await GetNftByAssetId(id);
    } catch (e) {
      console.log(e);
    }

    try {
      if (result.success) {
        setIsLoading(false);
        setAssets(result.asset);
      }
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };
  useEffect(() => {
    setIsLoading(true);
    if (json == 'nfts') {
      getCollection(id);
    } else if (json == 'items') {
      getNftByAssetId(id);
    }
    setIsLoading(false);
  }, [id]);

  const data = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
    datasets: [
      {
        label: 'Price',
        data: [0.6, 0.9, 1.2, 2.3, 2.4, 2.8],
        fill: true,
        backgroundColor: 'rgba(75,192,192,0.2)',
        borderColor: 'rgba(75,192,192,1)',
      },
    ],
  };
  const [tableData, setTableData] = useState([]);
  const columns = [
    {
      title: 'Event',
      dataIndex: 'event',
    },
    {
      title: 'Price',
      dataIndex: 'price',
    },
    {
      title: 'From',
      dataIndex: 'from',
    },
    {
      title: 'To',
      dataIndex: 'to',
    },
    {
      title: 'Date',
      dataIndex: 'date',
    },
  ];
  // useEffect(() => {
  //   setUser(state.User.user);
  // }, [state.User.user]);

  const getInventory = async () => {
    setIsLoading(true);
    let result;
    try {
      result = await getInventoryDetails(id);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
    try {
      if (result.success) {
        setIsLoading(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  function callback(key) {
    console.log(key);
  }
  const db = getDatabase();
  const auth = getAuth();
  const getData = (uid) => {
    const userId = auth?.currentUser?.uid;
    setUid(userId);
    return onValue(
      referance(db, process.env.REACT_APP_FIREBASE_URL + userId),
      (snapshot) => {
        const kyc =
          (snapshot.val() && snapshot.val().kycStatus) ||
          'Pending Verification';
        setKycVerification(kyc);
        // console.log(kyc)
      },
      {
        onlyOnce: true,
      }
    );
  };

  useEffect(() => {
    getData();
  }, [kycVerification]);
  return (
    <>
      {isLoading && <Loader className={styles.loader} />}
      {assets?._id && (
        <div className={cn('section', styles.section)} ref={ref}>
          <div className={cn('container', styles.container)}>
            <div className={styles.container1}>
              <div className={styles.bg}>
                <div className={styles.preview}>
                  {assets?.imageUrl !== '' ? (
                    <img
                      alt=""
                      src={
                        assets?.featuredImage ||
                        assets?.previewImage ||
                        assets?.imageUrl
                      }
                      onContextMenu={(e) => e.preventDefault()}
                    />
                  ) : (
                    <video
                      autoPlay
                      muted
                      loop
                      src={assets?.videoUrl}
                      onContextMenu={(e) => e.preventDefault()}
                    />
                  )}
                </div>
                {/* <div
                className={styles.preview}>
                  {assets.imageUrl || assets.previewImage  ? (
                    <>
                      <img
                      alt=""
                        src={(assets?.featuredImage ||assets.imageUrl || assets.previewImage || (assets.attachments[0]?.fileType === 'IMAGE' && assets.attachments[0]?.url))}
                      />


                    </>
                  ) : (
                    <video
                      autoPlay
                      muted
                      loop
                      src={(assets.videoUrl ? assets.videoUrl : (assets?.attachments[0]?.fileType === 'VIDEO' && assets.attachments[0]?.url))}
                      type="video/mp4"
                    />
                  )}
                  </div> */}
              </div>
              <div className={styles.details}>
                <div className={styles.title1}>
                  <h3 className={cn('h3', styles.title)}>
                    {assets?.name ? assets?.name : assets?.displayName}
                  </h3>
                </div>
                {assets.available && assets.supply && (
                  <div className={styles.priceRow}>
                    <Row>
                      <Col md={6} sm={7} xs={24}>
                        <div className={styles.priceHead}>Edition</div>
                      </Col>
                      <Col md={8} sm={14} xs={24}>
                        <p className={styles.test}>
                          {assets.available} of {assets.supply}
                        </p>
                      </Col>
                    </Row>
                  </div>
                )}
                {/* <div className={styles.title}> */}
                <div className={styles.priceRow}>
                  <Row>
                    <Col md={6} sm={7} xs={24}>
                      <div className={styles.priceHead}>Price</div>
                    </Col>

                    <Col md={18} sm={10} xs={24}>
                      <div className={styles.price}>
                        <div className={styles.titles}>
                          {/* {assets.chainName  === "Polygon" ? <img src={Polygon} className={styles.eth} alt="polygon" /> :
                        <FaEthereum className={styles.eth} alt="polygon" />} */}
                          <img
                            src={Polygon}
                            className={styles.eth}
                            alt="polygon"
                          />
                          {assets?.floorPrice >= 0
                            ? assets?.floorPrice
                            : assets?.tokenPrice}
                          <p>
                            ($
                            {(
                              value *
                              (assets?.floorPrice >= 0
                                ? assets?.floorPrice
                                : assets?.tokenPrice)
                            ).toFixed(3)}
                            )
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* </div> */}
                <div className={styles.priceRow}>
                  <Row>
                    <Col md={6} sm={7} xs={24}>
                      <div className={styles.priceHead}>Description</div>
                    </Col>
                    <Col md={18} sm={10} xs={24}>
                      <div className={styles.price}>{assets?.description}</div>
                    </Col>
                  </Row>
                </div>
                {assets?.tokenId && (
                  <div className={styles.priceRow}>
                    <Row>
                      <Col md={6} sm={7} xs={24}>
                        <div className={styles.priceHead}>Token Id</div>
                      </Col>
                      <Col md={18} sm={10} xs={24}>
                        <div className={styles.price}>{assets.tokenId}</div>
                      </Col>
                    </Row>
                  </div>
                )}
                <div className={styles.info}></div>

                <div className={styles.btns}>
                  {/* {kycVerification === "Failed"? null :  */}
                  <>
                    {assets.createdBy === 'wowTalkies' &&
                      (assets?.collectionId?.isPublic ||
                      (assets?.collectionId?.whitelistAddresses &&
                        assets?.collectionId?.whitelistAddresses.includes(
                          account
                        )) ? (
                        <button
                          className={cn('button', styles.button)}
                          onClick={() => {
                            setItem(assets);
                            setVisibleModalBid(true);
                          }}
                        >
                          Mint Now
                        </button>
                      ) : null)}
                  </>
                  {/* } */}
                  {assets?.tokenStandard !== 'ERC-1155' && (
                    <Link to={'/collections/' + assets._id}>
                      <button className={cn('button', styles.button)}>
                        View Collection
                      </button>
                    </Link>
                  )}
                </div>

                <div>
                  {/* <div className="detailcontainer">
                    <h2>DETALS:</h2>
                    <p>walletAddress:</p>
                    <p>Etherum:</p>
                  </div> */}
                </div>
                {/* <Control className={styles.control} /> */}
              </div>
            </div>
            <div className={styles.newcontainers}>
              <div className={styles.detail1}>
                <div className={styles.headingFlex}>
                  <p className={styles.text1}>Attributes</p>
                  {attributes ? (
                    <HiMinus
                      onClick={() => {
                        setAttributes(false);
                      }}
                    />
                  ) : (
                    <HiPlus
                      onClick={() => {
                        setAttributes(true);
                      }}
                    />
                  )}
                </div>
                {attributes && (
                  <p className={styles.of}>
                    <>
                      {assets.defaultAttributes?.length > 0
                        ? assets.defaultAttributes.map((x, index) => (
                            <Row>
                              <Col md={6} sm={7} xs={24}>
                                <p>{x.trait_type}</p>
                              </Col>
                              <Col md={18} sm={10} xs={24}>
                                <p>{x.value}</p>
                              </Col>
                            </Row>
                          ))
                        : 'No attributes found'}
                    </>
                  </p>
                )}
              </div>
              <div className={styles.detail}>
                <div className={styles.headingFlex}>
                  <p className={styles.text1}>Details</p>
                  {detail ? (
                    <HiMinus
                      onClick={() => {
                        setDetail(false);
                      }}
                    />
                  ) : (
                    <HiPlus
                      onClick={() => {
                        setDetail(true);
                      }}
                    />
                  )}
                </div>
                {detail && (
                  <div className={styles.of}>
                    <Row>
                      <Col md={6} sm={7} xs={24}>
                        <div className={styles.subhead}>Contract Type</div>
                      </Col>
                      <Col md={18} sm={10} xs={24}>
                        <p className={styles.textt}>
                          {assets?.contractType
                            ? assets?.contractType
                            : assets?.tokenStandard}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} sm={7} xs={24}>
                        <div className={styles.subhead}>Contract Address</div>
                      </Col>
                      <Col md={18} sm={10} xs={24}>
                        <p className={styles.textt}>
                          {assets.tokenStandard === 'ERC-721' ? (
                            <a
                              style={{ color: '#4a4a4a' }}
                              target="_blank"
                              rel="noreferrer"
                              href={
                                CONFIG.BlockchainUrl + assets?.contractAddress
                              }
                            >
                              {assets?.contractAddress}
                            </a>
                          ) : (
                            <a
                              style={{ color: '#4a4a4a' }}
                              target="_blank"
                              rel="noreferrer"
                              href={
                                CONFIG.BlockchainUrl +
                                assets?.collectionId?.contractAddress
                              }
                            >
                              {assets?.collectionId?.contractAddress}
                            </a>
                          )}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} sm={7} xs={24}>
                        <div className={styles.subhead}>Blockchain</div>
                      </Col>
                      <Col md={18} sm={10} xs={24}>
                        <p className={styles.description}>
                          {/* {assets?.chainName ? assets?.chainName :"Polygon"} */}
                          Polygon
                        </p>
                      </Col>
                    </Row>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* <div className={cn("container", styles.container)}>
           {(asset.comments.length>=1) && <div className={styles.bg}>
              <div className={styles.comments}>
                <h3 className={cn("h6", styles.title)}>Reviews</h3>

                <form
                  className={styles.search}
                  // onSubmit={(e) => handleSubmit(e, asset.id)}
                >
                  <input
                    className={styles.input}
                    type="text"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    name="comment"
                    placeholder="Leave your comments here"
                    // required
                  />
                  <button className={styles.result}>
                    <i
                      className="fa fa-fr fa-paper-plane"
                      style={{ color: "grey" }}
                    />
                  </button>
                </form>
              </div>
              <h3 className={cn("h6", styles.title)}>Comments</h3>

              <div>
                {Assets.comments.slice(0, limit).map((data) => {
                  return (
                    <div className={styles.allComments}>
                      <div className={styles.commentItem}>
                        <img
                          src={data.imageUrl}
                          className={styles.user}
                        />
                        <p className={styles.text}>{data.userName}</p>
                      </div>
                      <i
                        className="fa fa-fr fa-comments"
                        style={{ color: "grey" }}
                      />
                      <span className={styles.text}>{data.comment}</span>
                      <hr />
                    </div>
                  );
                })}
              </div>
              {limit === 2 && (
                <p
                  className={styles.load}
                  onClick={() => {
                    setLimit(asset.comments.length);
                  }}
                >
                  Load More....
                </p>
              )}
            </div>
        }
          </div> */}

          {/* <HotBid /> */}

          <Modal
            visible={visibleModalBid}
            onClose={() => setVisibleModalBid(false)}
          >
            <Purchase
              asset={inventoryItem}
              item={assets}
              onClose={() => setVisibleModalBid(false)}
            />
          </Modal>

          {openCheckout && (
            <div className={styles.checkout}>
              <div className={styles.maincheckout}>
                <button
                  className={styles.btnclose}
                  onClick={() => setOpenCheckout(false)}
                >
                  <div className={styles.btnx}>x</div>
                </button>
                <div className={styles.heading}>
                  <h3>Checkout</h3>
                </div>
                {/* <img className={styles.avatar}
                     src={
                     typeof item.imageUrl != "undefined"
                     ? item.imageUrl
                    : "https://thumbs.dreamstime.com/b/businessman-avatar-image-beard-hairstyle-male-profile-vector-illustration-178545831.jpg"
                   }
                  alt="Avatar"
                /> */}
                <img
                  className={styles.title2}
                  src={asset.previewImage}
                  alt="Avatar"
                  onContextMenu={(e) => e.preventDefault()}
                />
                <p className={styles.parg}>You are about to purchase a</p>{' '}
                <br />
                <span className={styles.bold1}>{asset.collectioName}</span>
                <br />
                {/* <span className={styles.bold2}>from John Antony</span> */}
                <div>
                  <TextInput1
                    type="number"
                    label="Quantity"
                    placeholder="Enter quantity"
                  />
                </div>
                <div className={styles.heading}>
                  <p>You will pay</p>
                  <div className={styles.subtotal}>{asset.tokenPrice} ETH</div>
                </div>
                <button className={styles.btncheckout}>Checkout</button>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default CollectionDetail;
