import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./SellerHome.module.sass";
import { Range, getTrackBackground } from "react-range";
import Slider from "react-slick";
import Icon from "../Icon";
import Card from "../SellerCard";
import Dropdown from "../Dropdown";
import {
  GetAssetpaginate,
  GetPostByTags,
} from "../../services/ApiServices";
import LoaderCircle from "../LoaderCircle";
import Loader from "../Loader";
import { useDispatch, useSelector } from "react-redux";
// import { getPosts } from "../../redux";

const navLinks = ["All items", "Art", "Game", "Photography", "Music", "Video"];

const dateOptions = ["Recently added", "Long added"];
const priceOptions = ["Highest price", "The lowest price"];
const likesOptions = ["Most liked", "Least liked"];
const creatorOptions = ["Verified only", "All", "Most liked"];
const sortingOptions = [];
navLinks.map((x) => sortingOptions.push(x));

const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
  <button {...props}>{children}</button>
);

const SellerHome = () => {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const [user,setUser] =useState({})
  const [activeIndex, setActiveIndex] = useState(0);
  const [date, setDate] = useState(dateOptions[0]);
  const [price, setPrice] = useState(priceOptions[0]);
  const [likes, setLikes] = useState(likesOptions[0]);
  const [creator, setCreator] = useState(creatorOptions[0]);
  const [sorting, setSorting] = useState(sortingOptions[0]);
  const [assets, setAssets] = useState([]);
  const [lastKey, setLastkey] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [values, setValues] = useState([5]);
  const [searchvalue,setSearchValue] = useState(getQueryVariable('search'));
  const [visible, setVisible] = useState(false);

  const STEP = 0.1;
  const MIN = 0.01;
  const MAX = 10;
  // if (state.Posts.isFirstLoaded === false) {
  //   dispatch(getPosts());
  // }
  
  function getQueryVariable(variable)
  {
          var query = window.location.search.substring(1);
          var vars = query.split("&");
          for (var i=0;i<vars.length;i++) {
                      var pair = vars[i].split("=");
          if(pair[0] == variable){return pair[1];}
           }
           return(false);
  }
  const SearchFunction=()=>{
  if(getQueryVariable('search')){
    let searchvalue=getQueryVariable('search');
    setAssets(state.Posts.searchposts.filter(data => {
      return data.title
          .toUpperCase()
          .includes(
            searchvalue.toUpperCase()
          );
  }))
  }
else{
  setAssets(state.Posts.posts)
}}
  useEffect(()=>{
    SearchFunction()
    setSearchValue(getQueryVariable('search'));
  },[searchvalue]);

  const getPostByTags = async (tag) => {
    if (tag === "All items") {
      setAssets(state.Posts.posts);
    } else {
      setIsLoading(true);
      setAssets([]);

      let result;
      try {
        result = await GetPostByTags(tag);
      } catch (e) {
        console.log(e);
      }

      try {
        if (result.success) {
          setIsLoading(false);

          setAssets(result.posts);
          setLastkey(result.lastKey);
        }
      } catch (e) {
        setIsLoading(false);

        console.log(e);
      }
    }
  };

  const getAssetspaginate = async () => {
    setIsLoading(true);

    let result;
    try {
      result = await GetAssetpaginate(lastKey);
    } catch (e) {
      console.log(e);
    }

    try {
      if (result.success) {
        setIsLoading(false);

        result.posts.map((data) => {
          setAssets((assets) => assets.concat(data));
        });
        setLastkey(result.lastKey);
      }
    } catch (e) {
      setIsLoading(false);

      console.log(e);
    }
  };
//   useEffect(() => {
//     setAssets(state.Posts.posts);
//   }, [state.User.userPost.length]);

//   useEffect(() => {
//     setLastkey(state.Posts.lastKey);
//   }, [state.Posts.lastKey]);
  useEffect(() => {
    setUser(state.User.user);
  }, [state.User.user]);

  useEffect(() => {
    setAssets(state.Posts.userPost);
    console.log(state.Posts);
  }, [state.Posts.userPost]);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    nextArrow: (
      <SlickArrow>
        <Icon name="arrow-next" size="14" />
      </SlickArrow>
    ),
    prevArrow: (
      <SlickArrow>
        <Icon name="arrow-prev" size="14" />
      </SlickArrow>
    ),
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 100000,
        settings: "unslick",
      },
    ],
  };

  return (
    <div className={cn("section", styles.section)}>
      <div className={cn("container", styles.container)}>
        
        <div className={styles.list}>
          <Slider
            className={cn("discover-slider", styles.slider)}
            {...settings}
          >
            {assets.map((x, index) => (
              <Card className={styles.card} item={x} key={index} />
            ))}
          </Slider>
        </div>
        {!isLoading && assets.length <= 0 && (
          <h2 className={styles.nopost}>No posts Available</h2>
        )}
        <div className={styles.btns}>
          {(state.Posts.postLoading || isLoading) && (
            <Loader className={styles.loader} />
          )}
        </div>
      </div>
    </div>
  );
};

export default SellerHome;
