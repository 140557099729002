import React from "react";
import cn from "classnames";
import styles from "./Users.module.sass";
import { Link } from "react-router-dom";
const Users = ({ className, items, nav }) => {
  return (
    <div className={cn(styles.users, className)}>
      <div className={styles.list}>
        <div className={styles.item}>
          {nav == "Chain Info" ? (
            <div className={styles.chainInfo}>
              <div className={styles.item}>
                <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE4LjExMDcgNy40Mzk4QzE3LjY2NzkgNy4xNzI0MSAxNy4wOTIzIDcuMTcyNDEgMTYuNjA1MiA3LjQzOThMMTMuMTUxMyA5LjQ4OTg1TDEwLjgwNDQgMTAuODI2OEw3LjM1MDU1IDEyLjg3NjlDNi45MDc3NSAxMy4xNDQzIDYuMzMyMSAxMy4xNDQzIDUuODQ1MDIgMTIuODc2OUwzLjA5OTYzIDExLjI3MjVDMi42NTY4MyAxMS4wMDUxIDIuMzQ2ODYgMTAuNTE0OSAyLjM0Njg2IDkuOTgwMDdWNi44MTU4OEMyLjM0Njg2IDYuMjgxMDkgMi42MTI1NSA1Ljc5MDg2IDMuMDk5NjMgNS41MjM0Nkw1LjgwMDc0IDMuOTYzNjVDNi4yNDM1NCAzLjY5NjI1IDYuODE5MTkgMy42OTYyNSA3LjMwNjI3IDMuOTYzNjVMMTAuMDA3NCA1LjUyMzQ2QzEwLjQ1MDIgNS43OTA4NiAxMC43NjAxIDYuMjgxMDkgMTAuNzYwMSA2LjgxNTg4VjguODY1OTJMMTMuMTA3IDcuNDg0MzdWNS40MzQzM0MxMy4xMDcgNC44OTk1NCAxMi44NDEzIDQuNDA5MzEgMTIuMzU0MiA0LjE0MTkxTDcuMzUwNTUgMS4yMDA1NUM2LjkwNzc1IDAuOTMzMTUxIDYuMzMyMSAwLjkzMzE1MSA1Ljg0NTAyIDEuMjAwNTVMMC43NTI3NjggNC4xNDE5MUMwLjI2NTY4MyA0LjQwOTMxIDAgNC44OTk1NCAwIDUuNDM0MzNWMTEuMzYxNkMwIDExLjg5NjQgMC4yNjU2ODMgMTIuMzg2NiAwLjc1Mjc2OCAxMi42NTRMNS44NDUwMiAxNS41OTU0QzYuMjg3ODIgMTUuODYyOCA2Ljg2MzQ3IDE1Ljg2MjggNy4zNTA1NSAxNS41OTU0TDEwLjgwNDQgMTMuNTg5OUwxMy4xNTEzIDEyLjIwODRMMTYuNjA1MiAxMC4yMDI5QzE3LjA0OCA5LjkzNTUxIDE3LjYyMzYgOS45MzU1MSAxOC4xMTA3IDEwLjIwMjlMMjAuODExOCAxMS43NjI3QzIxLjI1NDYgMTIuMDMwMSAyMS41NjQ2IDEyLjUyMDMgMjEuNTY0NiAxMy4wNTUxVjE2LjIxOTNDMjEuNTY0NiAxNi43NTQxIDIxLjI5ODkgMTcuMjQ0NCAyMC44MTE4IDE3LjUxMTdMMTguMTEwNyAxOS4xMTYxQzE3LjY2NzkgMTkuMzgzNSAxNy4wOTIzIDE5LjM4MzUgMTYuNjA1MiAxOS4xMTYxTDEzLjkwNDEgMTcuNTU2M0MxMy40NjEzIDE3LjI4ODkgMTMuMTUxMyAxNi43OTg3IDEzLjE1MTMgMTYuMjYzOVYxNC4yMTM5TDEwLjgwNDQgMTUuNTk1NFYxNy42NDU0QzEwLjgwNDQgMTguMTgwMiAxMS4wNzAxIDE4LjY3MDUgMTEuNTU3MiAxOC45Mzc5TDE2LjY0OTQgMjEuODc5MkMxNy4wOTIzIDIyLjE0NjYgMTcuNjY3OSAyMi4xNDY2IDE4LjE1NSAyMS44NzkyTDIzLjI0NzIgMTguOTM3OUMyMy42OSAxOC42NzA1IDI0IDE4LjE4MDIgMjQgMTcuNjQ1NFYxMS43MTgyQzI0IDExLjE4MzQgMjMuNzM0MyAxMC42OTMxIDIzLjI0NzIgMTAuNDI1N0wxOC4xMTA3IDcuNDM5OFoiIGZpbGw9IiM4MjQ3RTUiLz4KPC9zdmc+Cg==" />
                <div className={styles.name}>{items.blockchainName}</div>
              </div>
              <div className={styles.item}>
              <div className={styles.name}>Type:</div>
              <div>{items.assetType}</div>
              </div>
              <div className={styles.item}>
              <div className={styles.name}>Contract Address:</div>
              <div>{items.contractAddress}</div>
              </div>
              <div className={styles.item}>
              <div className={styles.name}>Token ID:</div>
              <div>{items.tokenId}</div>
              </div>
            </div>
          ) : (
            <div>
              <Link to={"/profile/" + items.userId.id}>
                <div className={styles.avatar}>
                  <img src={items.userId.imageUrl} alt="Avatar" />
                  <div className={styles.reward}>
                    <img src="/images/content/reward-1.svg" alt="Reward" />
                  </div>
                </div>
              </Link>
              <div className={styles.details}>
                <div className={styles.position}>Owner</div>
                <div className={styles.name}>{items.userId.userName}</div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Users;
