import React, { useEffect, useState } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Card7.module.sass";
import Icon from "../Icon";
import { GetUSD } from "../../services/ApiServices";
import { config } from "../../config";
import Bid from "../../mocks/bids";
import Grp from "../../components/img/Group 239.png"



const Card1 = ({ className, item }) => {
  const [visible, setVisible] = useState(false);
  const [value, setValue] = useState("")
  const getUSD = async () => {
    let result;
    try {
      result = await GetUSD();
    } catch (e) {
      console.log(e);

    }

    try {
      if (result) {
        setValue(result.USD)
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    getUSD()
  }, [])

  return (
    
    <div className={cn(styles.card, className)}>
            <div className={styles.avatarWrapper}>
          <img className={styles.avatar} src={item.userId.imageUrl} alt="Avatar" />
          <div className={styles.icon}>✓</div>
        
      </div>
        {item.attachments[0].fileType === "image" ? (
          <img
            className={styles.preview}
            srcSet={`${item.attachments[0].url} 2x`}
            src={item.attachments[0].url}
            alt="Card"
          />
          
        ) : (
          <video
            autoPlay
            muted
            loop
            src={item.attachments[0].url}
            type="video/mp4"
          />
        )}
        
        <div className={styles.counter}>{item.title}</div>

        {/* <div className={styles.control}>
          <div
            className={cn(
              { "status-green": item.category === "green" },
              styles.category
            )}
          >
            {item.categoryText}
          </div> */}
          {/* <button
            className={cn(styles.favorite, { [styles.active]: visible })}
            onClick={() => setVisible(!visible)}
          >
            <Icon name="heart" size="20" />
          </button> */}
          {/* <button className={cn("button-small", styles.button)}>
            <span>Place a bid</span>
            <Icon name="scatter-up" size="16" />
          </button> */}
        {/* </div> */}

        
        
        </div>
  );
};

export default Card1;