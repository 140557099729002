import React, { useEffect, useState } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Card6.module.sass";
import Icon from "../Icon";
import { GetUSD } from "../../services/ApiServices";
import { config } from "../../config";
import Bid from "../../mocks/bids";
import Grp from "../../components/img/Group 239.png"



const Card6 = ({ className, item }) => {
  const [visible, setVisible] = useState(false);
  const [value, setValue] = useState("")
  const getUSD = async () => {
    let result;
    try {
      result = await GetUSD();
    } catch (e) {
      console.log(e);

    }

    try {
      if (result) {
        setValue(result.USD)
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    getUSD()
  }, [])

  return (
    
    <div className={cn(styles.card, className)}>
     <Link className={styles.link} to={"/item/" + item._id}>
      <div className={styles.avatarWrapper}>
          <img className={styles.avatar} src={item.image} alt="Avatar" />
          {/* <div className={styles.icon}>✓</div> */}
      </div>
          <img
            className={styles.mainImage}
            // srcSet={`${item.attachments[0].url} 2x`}
            src={item?.assetsId?.imageUrl}
            alt="Card"
          />
        <div className={styles.line}>
        <div className={styles.line1}>
        <div >{item?.title}</div>
        <div className={item.title}>
        <img className={styles.eth} src={Grp} alt=""/>{item?.assetsId?.tokenPrice}</div>
        </div>

        <div className={styles.line2}>
        <div style={{width: '35%'}}><b>{item?.userName}</b></div>
        <div style={{display: 'flex',justifyContent: 'flex-  end'}}>{item.city}</div>   
        </div>

        </div>    
     </Link>
    </div>
  );
};

export default Card6;