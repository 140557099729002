import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import styles from './Card.module.sass';
import Polygon from '../../images/polygon.png';
import { GetMaticPrice } from '../../services/ApiServices';
import { Link } from 'react-router-dom';

const Card = ({ className, item, props }) => {
  const [value, setValue] = useState([]);

  const getUSD = async () => {
    let result;
    try {
      result = await GetMaticPrice();
    } catch (e) {
      console.log(e);
    }

    try {
      if (result) {
        setValue(result.USD);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getUSD();
  }, []);

  return (
    <div className={cn(styles.card, className)}>
      <div className={styles.cardPreview}>
        {/* <Link className={styles.link} title="items" to={`/collectionDetail/items/${item._id}`} > */}
        <Link
          className={styles.link}
          to={
            item?.tokenStandard === 'ERC-1155'
              ? `/collectionDetail/items/${item._id}`
              : `/item/collections/${item._id}`
          }
        >
          <div
            className={styles.preview}
            // style={{
            //           backgroundImage: `linear-gradient(rgba(25 76 107 / 33%), rgba(25 76 107 / 33%)),url(${item.previewImage})`,
            //           backgroundPosition:"center",
            //           backgroundRepeat:"no-repeat"
            //         }}
          >
            {/* <div className={styles.of}>
           <img src={item.previewImage} alt="Avatar" />
          </div> */}
            {/* {item.attachments[0].fileType == "IMAGE" ? ( */}
            {/* <img
            className={styles.attachment_img_design}
            src={item.previewImage?item.previewImage:item.imageUrl}
            alt="Card"
          /> */}
            {item?.imageUrl !== '' ? (
              <img
                className={styles.attachment_img_design}
                alt=""
                src={
                  item?.featuredImage || item?.previewImage || item?.imageUrl
                }
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src =
                    'https://wowtalkiesdevbucket.s3.ap-south-1.amazonaws.com/collections/no-image.png';
                }}
                onContextMenu={(e) => e.preventDefault()}
              />
            ) : (
              <video
                className={styles.attachment_img_design}
                autoPlay
                muted
                loop
                src={item?.videoUrl}
                onContextMenu={(e) => e.preventDefault()}
              />
            )}
            {/* ) : (
              <img
              // srcSet={`${item.attachments[0].url} 2x`}
              // srcSet={`${Pug} 2x`}
              // src={Pug}
              src={item.attachments[1].url}
              alt="Card"
            />
            )}  */}
          </div>
          <div>
            <h1 className={styles.collectionName}>
              {item.name ? item.name : item.displayName}
            </h1>
            <p className={styles.name}>{item.collectionName}</p>
            <div className={styles.price}>
              <div className={styles.imgFlex}>
                <img src={Polygon} className={styles.eth} alt="polygon" />
                <h4>{item.tokenPrice} MATIC</h4>
                {/* <p>Floor Price</p> */}
              </div>
              <div>
                <h4>$ {(value * item.tokenPrice).toFixed(2)}</h4>
                {/* <p>{item.volume}</p> */}
              </div>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Card;
