import { getProvider } from "../services/ApiServices";
import { ethers } from "ethers";
import Web3 from 'web3';
let bscAddress = "0xE10dd5A8e8D64B6424321B701E31071F81f55557";
let maticAddress = "0x3Bf35C271c07E42a5e23994669bfA0F1fBA44eA8";

async function currentProvider() {
    let selectedProvider = await getProvider();
    return new ethers.providers.Web3Provider(selectedProvider);
}

async function getERC20Balance(address) {
    const provider = await currentProvider();
    let weiBalance = await provider.getBalance(address);
    let balance = parseFloat(ethers.utils.formatEther(weiBalance));
    return balance;
}

async function mint() {
    let selectedProvider = await currentProvider();
    // Contract Instance
    const contractInstance = new ethers.Contract(bscAddress, [
        {
            "anonymous": false,
            "inputs": [
                {
                    "indexed": true,
                    "internalType": "address",
                    "name": "previousOwner",
                    "type": "address"
                },
                {
                    "indexed": true,
                    "internalType": "address",
                    "name": "newOwner",
                    "type": "address"
                }
            ],
            "name": "OwnershipTransferred",
            "type": "event"
        },
        {
            "anonymous": false,
            "inputs": [
                {
                    "indexed": false,
                    "internalType": "address",
                    "name": "account",
                    "type": "address"
                }
            ],
            "name": "Paused",
            "type": "event"
        },
        {
            "anonymous": false,
            "inputs": [
                {
                    "indexed": false,
                    "internalType": "uint256",
                    "name": "tokenId",
                    "type": "uint256"
                },
                {
                    "indexed": false,
                    "internalType": "uint256",
                    "name": "tokenPrice",
                    "type": "uint256"
                },
                {
                    "indexed": false,
                    "internalType": "address",
                    "name": "newOwner",
                    "type": "address"
                }
            ],
            "name": "TokenSold",
            "type": "event"
        },
        {
            "anonymous": false,
            "inputs": [
                {
                    "indexed": false,
                    "internalType": "address",
                    "name": "from",
                    "type": "address"
                },
                {
                    "indexed": false,
                    "internalType": "address",
                    "name": "to",
                    "type": "address"
                },
                {
                    "indexed": false,
                    "internalType": "uint256",
                    "name": "tokenId",
                    "type": "uint256"
                }
            ],
            "name": "TransferAsset",
            "type": "event"
        },
        {
            "anonymous": false,
            "inputs": [
                {
                    "indexed": false,
                    "internalType": "address",
                    "name": "account",
                    "type": "address"
                }
            ],
            "name": "Unpaused",
            "type": "event"
        },
        {
            "inputs": [
                {
                    "internalType": "uint256",
                    "name": "_tokenId",
                    "type": "uint256"
                }
            ],
            "name": "buyNFT",
            "outputs": [],
            "stateMutability": "payable",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "renounceOwnership",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "uint256",
                    "name": "_tokenId",
                    "type": "uint256"
                },
                {
                    "internalType": "uint256",
                    "name": "_price",
                    "type": "uint256"
                },
                {
                    "internalType": "string",
                    "name": "_assetUrl",
                    "type": "string"
                },
                {
                    "internalType": "uint256",
                    "name": "royalty",
                    "type": "uint256"
                }
            ],
            "name": "safeMintAsset",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "to",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "tokenId",
                    "type": "uint256"
                }
            ],
            "name": "safeTransferAsset",
            "outputs": [
                {
                    "internalType": "bool",
                    "name": "success",
                    "type": "bool"
                }
            ],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address payable",
                    "name": "_to",
                    "type": "address"
                }
            ],
            "name": "sendViaTransfer",
            "outputs": [],
            "stateMutability": "payable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "newOwner",
                    "type": "address"
                }
            ],
            "name": "transferOwnership",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "uint256",
                    "name": "_tokenId",
                    "type": "uint256"
                },
                {
                    "internalType": "uint256",
                    "name": "newPrice",
                    "type": "uint256"
                }
            ],
            "name": "updatePrice",
            "outputs": [
                {
                    "internalType": "bool",
                    "name": "success",
                    "type": "bool"
                }
            ],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "_nftContractAddress",
                    "type": "address"
                }
            ],
            "stateMutability": "nonpayable",
            "type": "constructor"
        },
        {
            "inputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "name": "assetTable",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "TokenId",
                    "type": "uint256"
                },
                {
                    "internalType": "uint256",
                    "name": "Price",
                    "type": "uint256"
                },
                {
                    "internalType": "address",
                    "name": "Owner",
                    "type": "address"
                },
                {
                    "internalType": "address",
                    "name": "Creator",
                    "type": "address"
                },
                {
                    "internalType": "string",
                    "name": "AssetUrl",
                    "type": "string"
                },
                {
                    "internalType": "uint256",
                    "name": "Royalty",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "uint256",
                    "name": "_tokenId",
                    "type": "uint256"
                }
            ],
            "name": "getAsset",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                },
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                },
                {
                    "internalType": "string",
                    "name": "",
                    "type": "string"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "user",
                    "type": "address"
                }
            ],
            "name": "getBalance",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "balance",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "uint256",
                    "name": "_tokenId",
                    "type": "uint256"
                }
            ],
            "name": "getTokenPrice",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "owner",
            "outputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "paused",
            "outputs": [
                {
                    "internalType": "bool",
                    "name": "",
                    "type": "bool"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        }
    ], selectedProvider.getSigner());

    // // const signer = provider.getSigner();
    // let tokenId = Math.floor(1000000000 + Math.random() * 900000000);

    // let tokenPrice = tokenWeb3.utils.toWei(price.toString(), 'ether');

    let tokenId = 1;
    let tokenPrice = 1;

    let url = "http://";
    let royalty = 0;
    // var web3 = new Web3(window.ethereum);
    // let signer.
    console.log("before send")
    let tx = await contractInstance.safeMintAsset(tokenId, tokenPrice, url, royalty);

    // Wait for the transaction to have 2 confirmations.
    // See the note below on "Economic Value" for considerations
    // regarding the number of suggested confirmations
    try {
        
    let receipt = await tx.wait(2);
    console.log("Mint receipt", receipt);
    return receipt
    } catch (error) {
        return console.log("Error from mint..",error.message);
    }

    // let contract = new web3.eth.Contract([
    //     {
    //         "anonymous": false,
    //         "inputs": [
    //             {
    //                 "indexed": true,
    //                 "internalType": "address",
    //                 "name": "previousOwner",
    //                 "type": "address"
    //             },
    //             {
    //                 "indexed": true,
    //                 "internalType": "address",
    //                 "name": "newOwner",
    //                 "type": "address"
    //             }
    //         ],
    //         "name": "OwnershipTransferred",
    //         "type": "event"
    //     },
    //     {
    //         "anonymous": false,
    //         "inputs": [
    //             {
    //                 "indexed": false,
    //                 "internalType": "address",
    //                 "name": "account",
    //                 "type": "address"
    //             }
    //         ],
    //         "name": "Paused",
    //         "type": "event"
    //     },
    //     {
    //         "anonymous": false,
    //         "inputs": [
    //             {
    //                 "indexed": false,
    //                 "internalType": "uint256",
    //                 "name": "tokenId",
    //                 "type": "uint256"
    //             },
    //             {
    //                 "indexed": false,
    //                 "internalType": "uint256",
    //                 "name": "tokenPrice",
    //                 "type": "uint256"
    //             },
    //             {
    //                 "indexed": false,
    //                 "internalType": "address",
    //                 "name": "newOwner",
    //                 "type": "address"
    //             }
    //         ],
    //         "name": "TokenSold",
    //         "type": "event"
    //     },
    //     {
    //         "anonymous": false,
    //         "inputs": [
    //             {
    //                 "indexed": false,
    //                 "internalType": "address",
    //                 "name": "from",
    //                 "type": "address"
    //             },
    //             {
    //                 "indexed": false,
    //                 "internalType": "address",
    //                 "name": "to",
    //                 "type": "address"
    //             },
    //             {
    //                 "indexed": false,
    //                 "internalType": "uint256",
    //                 "name": "tokenId",
    //                 "type": "uint256"
    //             }
    //         ],
    //         "name": "TransferAsset",
    //         "type": "event"
    //     },
    //     {
    //         "anonymous": false,
    //         "inputs": [
    //             {
    //                 "indexed": false,
    //                 "internalType": "address",
    //                 "name": "account",
    //                 "type": "address"
    //             }
    //         ],
    //         "name": "Unpaused",
    //         "type": "event"
    //     },
    //     {
    //         "inputs": [
    //             {
    //                 "internalType": "uint256",
    //                 "name": "_tokenId",
    //                 "type": "uint256"
    //             }
    //         ],
    //         "name": "buyNFT",
    //         "outputs": [],
    //         "stateMutability": "payable",
    //         "type": "function"
    //     },
    //     {
    //         "inputs": [],
    //         "name": "renounceOwnership",
    //         "outputs": [],
    //         "stateMutability": "nonpayable",
    //         "type": "function"
    //     },
    //     {
    //         "inputs": [
    //             {
    //                 "internalType": "uint256",
    //                 "name": "_tokenId",
    //                 "type": "uint256"
    //             },
    //             {
    //                 "internalType": "uint256",
    //                 "name": "_price",
    //                 "type": "uint256"
    //             },
    //             {
    //                 "internalType": "string",
    //                 "name": "_assetUrl",
    //                 "type": "string"
    //             },
    //             {
    //                 "internalType": "uint256",
    //                 "name": "royalty",
    //                 "type": "uint256"
    //             }
    //         ],
    //         "name": "safeMintAsset",
    //         "outputs": [],
    //         "stateMutability": "nonpayable",
    //         "type": "function"
    //     },
    //     {
    //         "inputs": [
    //             {
    //                 "internalType": "address",
    //                 "name": "to",
    //                 "type": "address"
    //             },
    //             {
    //                 "internalType": "uint256",
    //                 "name": "tokenId",
    //                 "type": "uint256"
    //             }
    //         ],
    //         "name": "safeTransferAsset",
    //         "outputs": [
    //             {
    //                 "internalType": "bool",
    //                 "name": "success",
    //                 "type": "bool"
    //             }
    //         ],
    //         "stateMutability": "nonpayable",
    //         "type": "function"
    //     },
    //     {
    //         "inputs": [
    //             {
    //                 "internalType": "address payable",
    //                 "name": "_to",
    //                 "type": "address"
    //             }
    //         ],
    //         "name": "sendViaTransfer",
    //         "outputs": [],
    //         "stateMutability": "payable",
    //         "type": "function"
    //     },
    //     {
    //         "inputs": [
    //             {
    //                 "internalType": "address",
    //                 "name": "newOwner",
    //                 "type": "address"
    //             }
    //         ],
    //         "name": "transferOwnership",
    //         "outputs": [],
    //         "stateMutability": "nonpayable",
    //         "type": "function"
    //     },
    //     {
    //         "inputs": [
    //             {
    //                 "internalType": "uint256",
    //                 "name": "_tokenId",
    //                 "type": "uint256"
    //             },
    //             {
    //                 "internalType": "uint256",
    //                 "name": "newPrice",
    //                 "type": "uint256"
    //             }
    //         ],
    //         "name": "updatePrice",
    //         "outputs": [
    //             {
    //                 "internalType": "bool",
    //                 "name": "success",
    //                 "type": "bool"
    //             }
    //         ],
    //         "stateMutability": "nonpayable",
    //         "type": "function"
    //     },
    //     {
    //         "inputs": [
    //             {
    //                 "internalType": "address",
    //                 "name": "_nftContractAddress",
    //                 "type": "address"
    //             }
    //         ],
    //         "stateMutability": "nonpayable",
    //         "type": "constructor"
    //     },
    //     {
    //         "inputs": [
    //             {
    //                 "internalType": "uint256",
    //                 "name": "",
    //                 "type": "uint256"
    //             }
    //         ],
    //         "name": "assetTable",
    //         "outputs": [
    //             {
    //                 "internalType": "uint256",
    //                 "name": "TokenId",
    //                 "type": "uint256"
    //             },
    //             {
    //                 "internalType": "uint256",
    //                 "name": "Price",
    //                 "type": "uint256"
    //             },
    //             {
    //                 "internalType": "address",
    //                 "name": "Owner",
    //                 "type": "address"
    //             },
    //             {
    //                 "internalType": "address",
    //                 "name": "Creator",
    //                 "type": "address"
    //             },
    //             {
    //                 "internalType": "string",
    //                 "name": "AssetUrl",
    //                 "type": "string"
    //             },
    //             {
    //                 "internalType": "uint256",
    //                 "name": "Royalty",
    //                 "type": "uint256"
    //             }
    //         ],
    //         "stateMutability": "view",
    //         "type": "function"
    //     },
    //     {
    //         "inputs": [
    //             {
    //                 "internalType": "uint256",
    //                 "name": "_tokenId",
    //                 "type": "uint256"
    //             }
    //         ],
    //         "name": "getAsset",
    //         "outputs": [
    //             {
    //                 "internalType": "uint256",
    //                 "name": "",
    //                 "type": "uint256"
    //             },
    //             {
    //                 "internalType": "address",
    //                 "name": "",
    //                 "type": "address"
    //             },
    //             {
    //                 "internalType": "string",
    //                 "name": "",
    //                 "type": "string"
    //             }
    //         ],
    //         "stateMutability": "view",
    //         "type": "function"
    //     },
    //     {
    //         "inputs": [
    //             {
    //                 "internalType": "address",
    //                 "name": "user",
    //                 "type": "address"
    //             }
    //         ],
    //         "name": "getBalance",
    //         "outputs": [
    //             {
    //                 "internalType": "uint256",
    //                 "name": "balance",
    //                 "type": "uint256"
    //             }
    //         ],
    //         "stateMutability": "view",
    //         "type": "function"
    //     },
    //     {
    //         "inputs": [
    //             {
    //                 "internalType": "uint256",
    //                 "name": "_tokenId",
    //                 "type": "uint256"
    //             }
    //         ],
    //         "name": "getTokenPrice",
    //         "outputs": [
    //             {
    //                 "internalType": "uint256",
    //                 "name": "",
    //                 "type": "uint256"
    //             }
    //         ],
    //         "stateMutability": "view",
    //         "type": "function"
    //     },
    //     {
    //         "inputs": [],
    //         "name": "owner",
    //         "outputs": [
    //             {
    //                 "internalType": "address",
    //                 "name": "",
    //                 "type": "address"
    //             }
    //         ],
    //         "stateMutability": "view",
    //         "type": "function"
    //     },
    //     {
    //         "inputs": [],
    //         "name": "paused",
    //         "outputs": [
    //             {
    //                 "internalType": "bool",
    //                 "name": "",
    //                 "type": "bool"
    //             }
    //         ],
    //         "stateMutability": "view",
    //         "type": "function"
    //     }
    // ], "0x3Bf35C271c07E42a5e23994669bfA0F1fBA44eA8");

    // try {
    //     let gas = contract.methods
    //         .safeMintAsset(tokenId, tokenPrice, url, royalty).estimateGas({
    //             from: "0x5aFD1A983D60D85627167282D0EF6F922a35d1eD"
    //         });
    // }
    // catch (e) {
    //     console.log("Estimate failed", e);
    //     return
    // }

    // contract.methods
    //     .safeMintAsset(tokenId, tokenPrice, url, royalty)
    //     .send({ from: "0x5aFD1A983D60D85627167282D0EF6F922a35d1eD", gasPrice: 10000000000, gas: 3000000 })
    //     .on('transactionHash', async function (hash) {
    //         console.log("mint hash", hash)
    //     })

}

async function approveBuy(contractAddress, provider, nftAmount) {
    // let ercAbi = [
    //     {
    //         "type": "constructor",
    //         "stateMutability": "nonpayable",
    //         "inputs": []
    //     },
    //     {
    //         "type": "event",
    //         "name": "Approval",
    //         "inputs": [
    //             {
    //                 "type": "address",
    //                 "name": "owner",
    //                 "internalType": "address",
    //                 "indexed": true
    //             },
    //             {
    //                 "type": "address",
    //                 "name": "spender",
    //                 "internalType": "address",
    //                 "indexed": true
    //             },
    //             {
    //                 "type": "uint256",
    //                 "name": "value",
    //                 "internalType": "uint256",
    //                 "indexed": false
    //             }
    //         ],
    //         "anonymous": false
    //     },
    //     {
    //         "type": "event",
    //         "name": "MetaTransactionExecuted",
    //         "inputs": [
    //             {
    //                 "type": "address",
    //                 "name": "userAddress",
    //                 "internalType": "address",
    //                 "indexed": false
    //             },
    //             {
    //                 "type": "address",
    //                 "name": "relayerAddress",
    //                 "internalType": "address payable",
    //                 "indexed": false
    //             },
    //             {
    //                 "type": "bytes",
    //                 "name": "functionSignature",
    //                 "internalType": "bytes",
    //                 "indexed": false
    //             }
    //         ],
    //         "anonymous": false
    //     },
    //     {
    //         "type": "event",
    //         "name": "RoleAdminChanged",
    //         "inputs": [
    //             {
    //                 "type": "bytes32",
    //                 "name": "role",
    //                 "internalType": "bytes32",
    //                 "indexed": true
    //             },
    //             {
    //                 "type": "bytes32",
    //                 "name": "previousAdminRole",
    //                 "internalType": "bytes32",
    //                 "indexed": true
    //             },
    //             {
    //                 "type": "bytes32",
    //                 "name": "newAdminRole",
    //                 "internalType": "bytes32",
    //                 "indexed": true
    //             }
    //         ],
    //         "anonymous": false
    //     },
    //     {
    //         "type": "event",
    //         "name": "RoleGranted",
    //         "inputs": [
    //             {
    //                 "type": "bytes32",
    //                 "name": "role",
    //                 "internalType": "bytes32",
    //                 "indexed": true
    //             },
    //             {
    //                 "type": "address",
    //                 "name": "account",
    //                 "internalType": "address",
    //                 "indexed": true
    //             },
    //             {
    //                 "type": "address",
    //                 "name": "sender",
    //                 "internalType": "address",
    //                 "indexed": true
    //             }
    //         ],
    //         "anonymous": false
    //     },
    //     {
    //         "type": "event",
    //         "name": "RoleRevoked",
    //         "inputs": [
    //             {
    //                 "type": "bytes32",
    //                 "name": "role",
    //                 "internalType": "bytes32",
    //                 "indexed": true
    //             },
    //             {
    //                 "type": "address",
    //                 "name": "account",
    //                 "internalType": "address",
    //                 "indexed": true
    //             },
    //             {
    //                 "type": "address",
    //                 "name": "sender",
    //                 "internalType": "address",
    //                 "indexed": true
    //             }
    //         ],
    //         "anonymous": false
    //     },
    //     {
    //         "type": "event",
    //         "name": "Transfer",
    //         "inputs": [
    //             {
    //                 "type": "address",
    //                 "name": "from",
    //                 "internalType": "address",
    //                 "indexed": true
    //             },
    //             {
    //                 "type": "address",
    //                 "name": "to",
    //                 "internalType": "address",
    //                 "indexed": true
    //             },
    //             {
    //                 "type": "uint256",
    //                 "name": "value",
    //                 "internalType": "uint256",
    //                 "indexed": false
    //             }
    //         ],
    //         "anonymous": false
    //     },
    //     {
    //         "type": "function",
    //         "stateMutability": "view",
    //         "outputs": [
    //             {
    //                 "type": "uint256",
    //                 "name": "",
    //                 "internalType": "uint256"
    //             }
    //         ],
    //         "name": "CHILD_CHAIN_ID",
    //         "inputs": []
    //     },
    //     {
    //         "type": "function",
    //         "stateMutability": "view",
    //         "outputs": [
    //             {
    //                 "type": "bytes",
    //                 "name": "",
    //                 "internalType": "bytes"
    //             }
    //         ],
    //         "name": "CHILD_CHAIN_ID_BYTES",
    //         "inputs": []
    //     },
    //     {
    //         "type": "function",
    //         "stateMutability": "view",
    //         "outputs": [
    //             {
    //                 "type": "bytes32",
    //                 "name": "",
    //                 "internalType": "bytes32"
    //             }
    //         ],
    //         "name": "DEFAULT_ADMIN_ROLE",
    //         "inputs": []
    //     },
    //     {
    //         "type": "function",
    //         "stateMutability": "view",
    //         "outputs": [
    //             {
    //                 "type": "bytes32",
    //                 "name": "",
    //                 "internalType": "bytes32"
    //             }
    //         ],
    //         "name": "DEPOSITOR_ROLE",
    //         "inputs": []
    //     },
    //     {
    //         "type": "function",
    //         "stateMutability": "view",
    //         "outputs": [
    //             {
    //                 "type": "string",
    //                 "name": "",
    //                 "internalType": "string"
    //             }
    //         ],
    //         "name": "ERC712_VERSION",
    //         "inputs": []
    //     },
    //     {
    //         "type": "function",
    //         "stateMutability": "view",
    //         "outputs": [
    //             {
    //                 "type": "uint256",
    //                 "name": "",
    //                 "internalType": "uint256"
    //             }
    //         ],
    //         "name": "ROOT_CHAIN_ID",
    //         "inputs": []
    //     },
    //     {
    //         "type": "function",
    //         "stateMutability": "view",
    //         "outputs": [
    //             {
    //                 "type": "bytes",
    //                 "name": "",
    //                 "internalType": "bytes"
    //             }
    //         ],
    //         "name": "ROOT_CHAIN_ID_BYTES",
    //         "inputs": []
    //     },
    //     {
    //         "type": "function",
    //         "stateMutability": "view",
    //         "outputs": [
    //             {
    //                 "type": "uint256",
    //                 "name": "",
    //                 "internalType": "uint256"
    //             }
    //         ],
    //         "name": "allowance",
    //         "inputs": [
    //             {
    //                 "type": "address",
    //                 "name": "owner",
    //                 "internalType": "address"
    //             },
    //             {
    //                 "type": "address",
    //                 "name": "spender",
    //                 "internalType": "address"
    //             }
    //         ]
    //     },
    //     {
    //         "type": "function",
    //         "stateMutability": "nonpayable",
    //         "outputs": [
    //             {
    //                 "type": "bool",
    //                 "name": "",
    //                 "internalType": "bool"
    //             }
    //         ],
    //         "name": "approve",
    //         "inputs": [
    //             {
    //                 "type": "address",
    //                 "name": "spender",
    //                 "internalType": "address"
    //             },
    //             {
    //                 "type": "uint256",
    //                 "name": "amount",
    //                 "internalType": "uint256"
    //             }
    //         ]
    //     },
    //     {
    //         "type": "function",
    //         "stateMutability": "view",
    //         "outputs": [
    //             {
    //                 "type": "uint256",
    //                 "name": "",
    //                 "internalType": "uint256"
    //             }
    //         ],
    //         "name": "balanceOf",
    //         "inputs": [
    //             {
    //                 "type": "address",
    //                 "name": "account",
    //                 "internalType": "address"
    //             }
    //         ]
    //     },
    //     {
    //         "type": "function",
    //         "stateMutability": "view",
    //         "outputs": [
    //             {
    //                 "type": "uint8",
    //                 "name": "",
    //                 "internalType": "uint8"
    //             }
    //         ],
    //         "name": "decimals",
    //         "inputs": []
    //     },
    //     {
    //         "type": "function",
    //         "stateMutability": "nonpayable",
    //         "outputs": [
    //             {
    //                 "type": "bool",
    //                 "name": "",
    //                 "internalType": "bool"
    //             }
    //         ],
    //         "name": "decreaseAllowance",
    //         "inputs": [
    //             {
    //                 "type": "address",
    //                 "name": "spender",
    //                 "internalType": "address"
    //             },
    //             {
    //                 "type": "uint256",
    //                 "name": "subtractedValue",
    //                 "internalType": "uint256"
    //             }
    //         ]
    //     },
    //     {
    //         "type": "function",
    //         "stateMutability": "nonpayable",
    //         "outputs": [],
    //         "name": "deposit",
    //         "inputs": [
    //             {
    //                 "type": "address",
    //                 "name": "user",
    //                 "internalType": "address"
    //             },
    //             {
    //                 "type": "bytes",
    //                 "name": "depositData",
    //                 "internalType": "bytes"
    //             }
    //         ]
    //     },
    //     {
    //         "type": "function",
    //         "stateMutability": "payable",
    //         "outputs": [
    //             {
    //                 "type": "bytes",
    //                 "name": "",
    //                 "internalType": "bytes"
    //             }
    //         ],
    //         "name": "executeMetaTransaction",
    //         "inputs": [
    //             {
    //                 "type": "address",
    //                 "name": "userAddress",
    //                 "internalType": "address"
    //             },
    //             {
    //                 "type": "bytes",
    //                 "name": "functionSignature",
    //                 "internalType": "bytes"
    //             },
    //             {
    //                 "type": "bytes32",
    //                 "name": "sigR",
    //                 "internalType": "bytes32"
    //             },
    //             {
    //                 "type": "bytes32",
    //                 "name": "sigS",
    //                 "internalType": "bytes32"
    //             },
    //             {
    //                 "type": "uint8",
    //                 "name": "sigV",
    //                 "internalType": "uint8"
    //             }
    //         ]
    //     },
    //     {
    //         "type": "function",
    //         "stateMutability": "pure",
    //         "outputs": [
    //             {
    //                 "type": "uint256",
    //                 "name": "",
    //                 "internalType": "uint256"
    //             }
    //         ],
    //         "name": "getChainId",
    //         "inputs": []
    //     },
    //     {
    //         "type": "function",
    //         "stateMutability": "view",
    //         "outputs": [
    //             {
    //                 "type": "bytes32",
    //                 "name": "",
    //                 "internalType": "bytes32"
    //             }
    //         ],
    //         "name": "getDomainSeperator",
    //         "inputs": []
    //     },
    //     {
    //         "type": "function",
    //         "stateMutability": "view",
    //         "outputs": [
    //             {
    //                 "type": "uint256",
    //                 "name": "nonce",
    //                 "internalType": "uint256"
    //             }
    //         ],
    //         "name": "getNonce",
    //         "inputs": [
    //             {
    //                 "type": "address",
    //                 "name": "user",
    //                 "internalType": "address"
    //             }
    //         ]
    //     },
    //     {
    //         "type": "function",
    //         "stateMutability": "view",
    //         "outputs": [
    //             {
    //                 "type": "bytes32",
    //                 "name": "",
    //                 "internalType": "bytes32"
    //             }
    //         ],
    //         "name": "getRoleAdmin",
    //         "inputs": [
    //             {
    //                 "type": "bytes32",
    //                 "name": "role",
    //                 "internalType": "bytes32"
    //             }
    //         ]
    //     },
    //     {
    //         "type": "function",
    //         "stateMutability": "view",
    //         "outputs": [
    //             {
    //                 "type": "address",
    //                 "name": "",
    //                 "internalType": "address"
    //             }
    //         ],
    //         "name": "getRoleMember",
    //         "inputs": [
    //             {
    //                 "type": "bytes32",
    //                 "name": "role",
    //                 "internalType": "bytes32"
    //             },
    //             {
    //                 "type": "uint256",
    //                 "name": "index",
    //                 "internalType": "uint256"
    //             }
    //         ]
    //     },
    //     {
    //         "type": "function",
    //         "stateMutability": "view",
    //         "outputs": [
    //             {
    //                 "type": "uint256",
    //                 "name": "",
    //                 "internalType": "uint256"
    //             }
    //         ],
    //         "name": "getRoleMemberCount",
    //         "inputs": [
    //             {
    //                 "type": "bytes32",
    //                 "name": "role",
    //                 "internalType": "bytes32"
    //             }
    //         ]
    //     },
    //     {
    //         "type": "function",
    //         "stateMutability": "nonpayable",
    //         "outputs": [],
    //         "name": "grantRole",
    //         "inputs": [
    //             {
    //                 "type": "bytes32",
    //                 "name": "role",
    //                 "internalType": "bytes32"
    //             },
    //             {
    //                 "type": "address",
    //                 "name": "account",
    //                 "internalType": "address"
    //             }
    //         ]
    //     },
    //     {
    //         "type": "function",
    //         "stateMutability": "view",
    //         "outputs": [
    //             {
    //                 "type": "bool",
    //                 "name": "",
    //                 "internalType": "bool"
    //             }
    //         ],
    //         "name": "hasRole",
    //         "inputs": [
    //             {
    //                 "type": "bytes32",
    //                 "name": "role",
    //                 "internalType": "bytes32"
    //             },
    //             {
    //                 "type": "address",
    //                 "name": "account",
    //                 "internalType": "address"
    //             }
    //         ]
    //     },
    //     {
    //         "type": "function",
    //         "stateMutability": "nonpayable",
    //         "outputs": [
    //             {
    //                 "type": "bool",
    //                 "name": "",
    //                 "internalType": "bool"
    //             }
    //         ],
    //         "name": "increaseAllowance",
    //         "inputs": [
    //             {
    //                 "type": "address",
    //                 "name": "spender",
    //                 "internalType": "address"
    //             },
    //             {
    //                 "type": "uint256",
    //                 "name": "addedValue",
    //                 "internalType": "uint256"
    //             }
    //         ]
    //     },
    //     {
    //         "type": "function",
    //         "stateMutability": "nonpayable",
    //         "outputs": [],
    //         "name": "initialize",
    //         "inputs": [
    //             {
    //                 "type": "string",
    //                 "name": "name_",
    //                 "internalType": "string"
    //             },
    //             {
    //                 "type": "string",
    //                 "name": "symbol_",
    //                 "internalType": "string"
    //             },
    //             {
    //                 "type": "uint8",
    //                 "name": "decimals_",
    //                 "internalType": "uint8"
    //             },
    //             {
    //                 "type": "address",
    //                 "name": "childChainManager",
    //                 "internalType": "address"
    //             }
    //         ]
    //     },
    //     {
    //         "type": "function",
    //         "stateMutability": "view",
    //         "outputs": [
    //             {
    //                 "type": "string",
    //                 "name": "",
    //                 "internalType": "string"
    //             }
    //         ],
    //         "name": "name",
    //         "inputs": []
    //     },
    //     {
    //         "type": "function",
    //         "stateMutability": "nonpayable",
    //         "outputs": [],
    //         "name": "renounceRole",
    //         "inputs": [
    //             {
    //                 "type": "bytes32",
    //                 "name": "role",
    //                 "internalType": "bytes32"
    //             },
    //             {
    //                 "type": "address",
    //                 "name": "account",
    //                 "internalType": "address"
    //             }
    //         ]
    //     },
    //     {
    //         "type": "function",
    //         "stateMutability": "nonpayable",
    //         "outputs": [],
    //         "name": "revokeRole",
    //         "inputs": [
    //             {
    //                 "type": "bytes32",
    //                 "name": "role",
    //                 "internalType": "bytes32"
    //             },
    //             {
    //                 "type": "address",
    //                 "name": "account",
    //                 "internalType": "address"
    //             }
    //         ]
    //     },
    //     {
    //         "type": "function",
    //         "stateMutability": "view",
    //         "outputs": [
    //             {
    //                 "type": "string",
    //                 "name": "",
    //                 "internalType": "string"
    //             }
    //         ],
    //         "name": "symbol",
    //         "inputs": []
    //     },
    //     {
    //         "type": "function",
    //         "stateMutability": "view",
    //         "outputs": [
    //             {
    //                 "type": "uint256",
    //                 "name": "",
    //                 "internalType": "uint256"
    //             }
    //         ],
    //         "name": "totalSupply",
    //         "inputs": []
    //     },
    //     {
    //         "type": "function",
    //         "stateMutability": "nonpayable",
    //         "outputs": [
    //             {
    //                 "type": "bool",
    //                 "name": "",
    //                 "internalType": "bool"
    //             }
    //         ],
    //         "name": "transfer",
    //         "inputs": [
    //             {
    //                 "type": "address",
    //                 "name": "recipient",
    //                 "internalType": "address"
    //             },
    //             {
    //                 "type": "uint256",
    //                 "name": "amount",
    //                 "internalType": "uint256"
    //             }
    //         ]
    //     },
    //     {
    //         "type": "function",
    //         "stateMutability": "nonpayable",
    //         "outputs": [
    //             {
    //                 "type": "bool",
    //                 "name": "",
    //                 "internalType": "bool"
    //             }
    //         ],
    //         "name": "transferFrom",
    //         "inputs": [
    //             {
    //                 "type": "address",
    //                 "name": "sender",
    //                 "internalType": "address"
    //             },
    //             {
    //                 "type": "address",
    //                 "name": "recipient",
    //                 "internalType": "address"
    //             },
    //             {
    //                 "type": "uint256",
    //                 "name": "amount",
    //                 "internalType": "uint256"
    //             }
    //         ]
    //     },
    //     {
    //         "type": "function",
    //         "stateMutability": "nonpayable",
    //         "outputs": [],
    //         "name": "withdraw",
    //         "inputs": [
    //             {
    //                 "type": "uint256",
    //                 "name": "amount",
    //                 "internalType": "uint256"
    //             }
    //         ]
    //     }
    // ];

    // let contractInterface = new ethers.utils.Interface(ercAbi);
    // let tokenContract = new ethers.Contract(
    //     contractAddress,
    //     ercAbi,
    //     provider.getSigner()
    // );


    // let amount = ethers.utils.parseEther(nftAmount.toString());
    // let nonce = await tokenContract.getNonce(userAddress);

    // let functionSignature = contractInterface.encodeFunctionData("approve", [contractAddress, amount]);

}


export { getERC20Balance, currentProvider, mint }