import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./Popular.module.sass";
import { Range, getTrackBackground } from "react-range";
import Slider from "react-slick";
import Icon from "../../../components/Icon";
import Card from "../../../components/Card1";
import Dropdown from "../../../components/Dropdown";
import {
  Getcollection,
  GetPostByTags,
} from "../../../services/ApiServices";
import LoaderCircle from "../../../components/LoaderCircle";
import Loader from "../../../components/Loader";
import { useDispatch, useSelector } from "react-redux";
// import { getPosts } from "../../../redux";
import Bid from "../../../mocks/bids";
// import {Posts} from "../../../json/Assets"
import Newitem from "../Topseller";

const navLinks = ["All items", "Bond", "Croud funding", "Derivatives", "PhotoPrivate & Placement"];

const dateOptions = ["Recently added", "Long added"];
const priceOptions = ["Highest price", "The lowest price"];
const likesOptions = ["Most liked", "Least liked"];
const creatorOptions = ["Verified only", "All", "Most liked"];
const sortingOptions = [];
navLinks.map((x) => sortingOptions.push(x));

const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
  <button {...props}>{children}</button>
);

const Popular = () => {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();

  const [activeIndex, setActiveIndex] = useState(0);
  const [date, setDate] = useState(dateOptions[0]);
  const [price, setPrice] = useState(priceOptions[0]);
  const [likes, setLikes] = useState(likesOptions[0]);
  const [creator, setCreator] = useState(creatorOptions[0]);
  const [sorting, setSorting] = useState(sortingOptions[0]);
  const [Assets, setAssets] = useState([]);
 
    
  const [lastKey, setLastkey] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [values, setValues] = useState([5]);
  const [searchvalue,setSearchValue] = useState(getQueryVariable('search'));
  const [visible, setVisible] = useState(false);

  const STEP = 0.1;
  const MIN = 0.01;
  const MAX = 10;
  
 
  
  // if (state.Posts.isFirstLoaded === false) {
  //   dispatch(getPosts());
  // }
  


  function getQueryVariable(variable)
  {
          var query = window.location.search.substring(1);
          var vars = query.split("&");
          for (var i=0;i<vars.length;i++) {
                      var pair = vars[i].split("=");
          if(pair[0] === variable){return pair[1];}
           }
           return(false);
  }
  const SearchFunction=()=>{
  if(getQueryVariable('search')){
    let searchvalue=getQueryVariable('search');
    setAssets(state.Posts.searchposts.filter(data => {
      return data.title
          .toUpperCase()
          .includes(
            searchvalue.toUpperCase()
          );
  }))
  }
else{
  setAssets(state.Posts.posts)
}}
  useEffect(()=>{
    // SearchFunction()
    setSearchValue(getQueryVariable('search'));
  },[searchvalue]);

  const getPostByTags = async (tag) => {
    if (tag === "All items") {
      setAssets(state.Posts.posts);
    } else {
      setIsLoading(true);
      setAssets([]);

      let result;
      try {
        result = await Getcollection(tag);
      } catch (e) {
        console.log(e);
      }

      try {
        if (result.success) {
          setIsLoading(false);
          setAssets(result.collection.docs);
          setLastkey(result.lastKey);
        }
      } catch (e) {
        setIsLoading(false);
        console.log(e);
      }
    }
  };
  useEffect(() => {
    getAssetspaginate()
  },[])
  
  console.log(Getcollection)
  const getAssetspaginate = async () => {
    setIsLoading(true);

    let result;
    try {
      result = await Getcollection();
      console.log("Assetresult",result)
      
      if (result&&result.success) {
        setIsLoading(false);

        // result.posts.map((data) => {
        //   setAssets((assets) => assets.concat(data));
        // });
        setAssets(result.collection.docs);
        console.log(result.collection.docs)
      }
    } 
    catch (e) {
      console.log(e);
    }

    // try {
    //   if (result.success) {
    //     setIsLoading(false);

    //     // result.posts.map((data) => {
    //     //   setAssets((assets) => assets.concat(data));
    //     // });
    //     setAssets(result.assetss);
    //     console.log(result.assetss)
    //   }
    // } catch (e) {
    //   setIsLoading(false);

    //   console.log(e);
    // }
  };
  const Cardvisible = async () => {
   <Card />
  };

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    nextArrow: (
      <SlickArrow>
        <Icon name="arrow-next" size="14" />
      </SlickArrow>
    ),
    prevArrow: (
      <SlickArrow>
      </SlickArrow>
    ),
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 100000,
        settings: "unslick",
      },
    ],
  };

  return (  
      <div className={cn("container", styles.container)}>
      <h3  className={cn("h4", styles.title)}>Hot Collections</h3>
      <div  className={styles.line}></div>
        <div className={cn(styles.filters, { [styles.active]: visible })}>
          <div className={styles.sorting}>
            <div className={styles.cell}>
              <div className={styles.label}>Price</div>
              <Dropdown
                className={styles.dropdown}
                value={price}
                setValue={setPrice}
                options={priceOptions}
              />
            </div>
            <div className={styles.cell}>
              <div className={styles.label}>likes</div>
              <Dropdown
                className={styles.dropdown}
                value={likes}
                setValue={setLikes}
                options={likesOptions}
              />
            </div>
            <div className={styles.cell}>
              <div className={styles.label}>creator</div>
              <Dropdown
                className={styles.dropdown}
                value={creator}
                setValue={setCreator}
                options={creatorOptions}
              />
            </div>
            <div className={styles.cell}>
              <div className={styles.label}>Price range</div>
              <Range
                values={values}
                step={STEP}
                min={MIN}
                max={MAX}
                onChange={(values) => setValues(values)}
                renderTrack={({ props, children }) => (
                  <div
                    onMouseDown={props.onMouseDown}
                    onTouchStart={props.onTouchStart}
                    style={{
                      ...props.style,
                      height: "27px",
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    <div
                      ref={props.ref}
                      style={{
                        height: "8px",
                        width: "100%",
                        borderRadius: "4px",
                        backgroundColor:"#3CC5C5"
                        
                      }}
                    >
                      {children}
                    </div>
                  </div>
                )}
                renderThumb={({ props, isDragged }) => (
                  <div
                    {...props}
                    style={{
                      ...props.style,
                      height: "24px",
                      width: "24px",
                      borderRadius: "50%",
                      backgroundColor: "#3772FF",
                      border: "4px solid #FCFCFD",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: "-33px",
                        color: "#fff",
                        fontWeight: "600",
                        fontSize: "14px",
                        lineHeight: "18px",
                        fontFamily: "Poppins",
                        padding: "4px 8px",
                        borderRadius: "8px",
                        backgroundColor: "#141416",
                      }}
                    >
                      {values[0].toFixed(1)}
                    </div>
                  </div>
                )}
              />
              <div className={styles.scale}>
                <div className={styles.number}>0.01 {Bid}</div>
                <div className={styles.number}>10 {Bid}</div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.list}>
          <Slider
            className={cn("discover-slider", styles.slider)}
            {...settings}
          >
            {Assets.map((x, index) => (
              
              <Card className={styles.card} item={x} key={index} />
            ))}
          </Slider>
        </div>
      </div>
  );
};

export default Popular;
