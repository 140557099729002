
export const GETPOSTS = 'GETPOSTS'
export const GETSEARCHPOSTS = 'GETSEARCHPOSTS'
export const CLEARGETPOSTS = 'CLEARGETPOSTS'
export const GETLASTKEY = 'GETLASTKEY'
export const GETUSERPOSTS = 'GETUSERPOSTS'

export const SETFIRSTLOADED = 'SETFIRSTLOADED'
export const GETPOSTSLOADNIG = 'GETPOSTSLOADNIG'
export const CLEARGETPOSTSLOADNIG = 'CLEARGETPOSTSLOADNIG'

export const CREATE_POST = 'CREATE_POST'
export const CREATE_POST_RESPONSE = 'CREATE_POST_RESPONSE'
export const CREATE_POST_RESPONSE_CLEAR = 'CREATE_POST_RESPONSE_CLEAR'
export const CREATE_POST_ERROR = 'CREATE_POST_ERROR'
export const CREATE_POST_ERROR_CLEAR = 'CREATE_POST_ERROR_CLEAR'

export const EDIT_POST = 'EDIT_POST'
export const EDIT_POST_RESPONSE = 'EDIT_POST_RESPONSE'
export const EDIT_POST_RESPONSE_CLEAR = 'EDIT_POST_RESPONSE_CLEAR'
export const EDIT_POST_ERROR = 'EDIT_POST_ERROR'
export const EDIT_POST_ERROR_CLEAR = 'EDIT_POST_ERROR_CLEAR'

export const POST_CANCELLED = 'POST_CANCELLED'
export const POST_ADDED = 'POST_ADDED'

export const POST_CANCELLED_CLEAR = 'POST_CANCELLED_CLEAR'


export const CLEARPOSTS = 'CLEARPOSTS'
