import React, { useState, useEffect } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Selection2.module.sass";
import Icon from "../../../components/Icon";
import { useDispatch, useSelector } from "react-redux";
import Card from "../../../components/Card";
import Reveal from 'react-awesome-reveal';
import { keyframes } from "@emotion/react";
import Image from "../../../components/Image";


const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;


const Selection2 = () => (
    <div className={styles.container} >
    <h3 className={cn("h4", styles.title)}>Why partner with Securrency Capital</h3>
<div className={styles.line}></div>
<div className={styles.desc}>
<p>While we take pride in being the first and largest marketplace and in our robust feature set,<br/> we also help our partners succeed with the following...</p>
</div>

        <div className={styles.row}>
                <div className={styles.feature}>  
                  <Reveal className='onStep' keyframes={fadeInUp} delay={100} duration={600} triggerOnce>
                  <div>
                  <img  className={styles.img} srcSet="/images/content/gr11.png"  alt="" />
                  <p className={styles.text}>Affordable</p>
                  <p className={styles.text1}>Reduced costs associated with blockchain-based digital asset technologies</p>
                  </div>
                  </Reveal> 
                  <div className={styles.line1}/>                
                </div>

                <div className={styles.feature}>  
                  <Reveal className='onStep' keyframes={fadeInUp} delay={100} duration={600} triggerOnce>
                  <div>
                  <img className={styles.img} srcSet="/images/content/gr12.png"  alt="" />
                  <p className={styles.text}>Accessible</p>
                  <p className={styles.text1}>Asset tokenization makes obscure, private, and other difficult-to-access financial markets accessible to a wider audience</p>
                  </div>
                  </Reveal>  
                  <div className={styles.line1}/>                
                </div>

                <div className={styles.feature}>  
                  <Reveal className='onStep' keyframes={fadeInUp} delay={100} duration={600} triggerOnce>
                  <div>
                  <img className={styles.img} srcSet="/images/content/gr13.png" alt="" />
                  <p className={styles.text}>Inclusive</p>
                  <p className={styles.text1}>Purchasing tokenized fractional shares allows you to tailor investments to your interests and finances</p>
                  </div>
                  </Reveal> 
                  <div className={styles.line1}/>                
                </div>     
        </div>
        <br></br>
        <div className={styles.row1}>
                <div className={styles.feature}>  
                  <Reveal className='onStep' keyframes={fadeInUp} delay={100} duration={600} triggerOnce>
                  <div>
                  <img  className={styles.img} srcSet="/images/content/gr14.png"  alt="" />
                  <p className={styles.text}>Safe and Secure</p>
                  <p className={styles.text1}>Reduced risk of fraud, theft, and negligence associated with financial regulatory compliance</p>
                  </div>
                  </Reveal> 
                  <div className={styles.line1}/>                
                </div>

                <div className={styles.feature}>  
                  <Reveal className='onStep' keyframes={fadeInUp} delay={100} duration={600} triggerOnce>
                  <div>
                  <img className={styles.img} srcSet="/images/content/gr15.png"  alt="" />
                  <p className={styles.text}>Easy to use</p>
                  <p className={styles.text1}>A simplified account opening process helps you start achieving your financial goals faster</p>
                  </div>
                  </Reveal> 
                  <div className={styles.line1}/>                
                </div>

                <div className={styles.feature}>  
                  <Reveal className='onStep' keyframes={fadeInUp} delay={100} duration={600} triggerOnce>
                  <div>
                  <img className={styles.img} srcSet="/images/content/gr16.png"  alt="" />
                  <p className={styles.text}>Breadth of Categories</p>
                  <p className={styles.text1}>With over thousands of collections, we are proud to host the widest range of categories, ranging from regulated digital assets to NFTs</p>
                  </div>
                  </Reveal> 
                  <div className={styles.line1}/>                
                </div>     
        </div>
    
    </div>
);

export default Selection2;