import React, {useState,useRef} from "react";
import cn from "classnames";
import styles from "./Mail.module.sass";


const Mail = () =>{

 
    return (
        <>
        <div className={cn("container", styles.container)}>
          <h3 className={cn("h4", styles.title)}>Support</h3>
          <p className={styles.supporttext}>
          Got any queries? Connect with us 
         <a href="mailto:support@wowtalkies.com"> support@wowtalkies.com </a>
         </p>
            </div>
        </>
      );


};
export default Mail;