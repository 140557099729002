import React from "react";
import Hero from "./Hero";
import Selection from "./Selection";
import Popular from "./Popular";
import HotBid from "../../components/HotBid";
import Collections from "./Collections";
import TopSeller from "./Topseller";
import styles from "./Home.module.sass";
// import Discover from "./Discover";
import Newitem from "./Newitems";
import Description from "./Description";
import { getUsers } from "../../redux";
import { useDispatch, useSelector } from 'react-redux'
import NewLanding from "./newLanding";


const Home = (props) => {
  const state = useSelector(state => state)
  const dispatch = useDispatch()
//   if(state.Posts.isFirstLoaded===false){
//     dispatch(getPosts())
// }
if(state.User.usersLoading===true)
{
  dispatch(getUsers())
}
  return (
    <div>
      <Description />
      {/* <NewLanding /> */}
    </div>
  );
};

export default Home;
